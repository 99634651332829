/**
*
* Formulaires
*
**/

/*========================================
=            Form par default            =
========================================*/

/*----------  Header / Footer  ----------*/

.form--header, .form--footer {
	margin-top: 20px;
}

/*----------  Checkbox  ----------*/
.checkbox {}
.checkbox--value {}

/*----------  Checkbox  ----------*/
.radio {}
.radio--value {}

/*----------  Checkbox & Radio other  ----------*/
.checkbox--other, .radio--other {
	label {
		width: auto;
	}
	input[type="checkbox"] {
		margin-top: 8px;
	}
}
	.checkbox--other-input, .radio--other-input {
		width: auto;
    	display: inline;
		margin-left: 1rem;
	}

/*----------  Radios visual  ----------*/
.radio--visual {}
.radio--visual-item {}
.radio--visual-img{}




/*=====  End of Form par default  ======*/
