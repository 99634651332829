/*	--------------------------------------------------
	--------------------------------------------------
		Agencement des blocs structurants :
	container, header, content, sidebar, footer
--------------------------------------------------
--------------------------------------------------*/

body {
    &.page-home {
        overflow-x: hidden;
    }
}
.header {
    .header--topbar {
        background-color: $primary;
        padding: 5px 0;
        button.navbar-toggler {
            background-color: #ffffff;
            margin: 4px 0 10px 10px;
            padding: 0;
            border: transparent;
            width: 60px;
            height: 40px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            z-index: 10000;
            position: relative;
            @include media-breakpoint-up(lg) {
                display: none;
            }
            &:hover, &:focus, &:active {
                outline: none;
                box-shadow: none;
            }
            span {
                width: 40px;
                height: 3px;
                background-color: $primary;
                margin: 7px 0;
                transition: margin .3s;
            }
            &::before, &::after {
                content: "";
                width: 40px;
                height: 3px;
                background-color: $primary;
                display: block;
            }
            &.collapsed {
                span {
                    margin: 4px 0;
                }
            }
        }
        .container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            @include media-breakpoint-up(lg) {
                flex-wrap: nowrap;
                justify-content: flex-start;
            }
        }
    }
    .header--nav-mobile {
        .logo {
            padding: 0;
            margin: 30px 0 0 0;
            text-align: center;
            display: block;
        }
    }
}
.logo {

}

.content {}

.main {}
.right-bar {}

.footer {
    .footer--boxes-bloc {
        display: flex;
        align-items: center;
        margin: 7px 0;
        flex-direction: column;
        justify-content: center;
        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
        &.blocThree-rs {
            > p {
                margin: 0;
                font-weight: 600;
                color: #000;
                @include media-breakpoint-up(lg) {
                    margin: 0 10px 0 0;
                }
            }
        }
        &.blocFour {
            a {
                color: #383838;
                font-size: 0.9rem;
                font-weight: 300;
            }
            p {
                margin: 0;
                color: #383838;
                font-weight: 300;
                font-size: 0.9rem;
            }
        }
    }
}
.page-catalog {
    header {
        .header--photofull {
            padding: 90px 0;
            .box-cat-search {
                border-radius: 50px !important;
            }
        }
    }
    .content {
        overflow: hidden;
        @media (min-width: 1400px) {
        }
        .catalog-deco-bloc {
            position: absolute;
            width: 1280px;
            height: 1272px;
            top: 0;
            left: 0;
            overflow: hidden;
            z-index: -1;
            display: none;
            @media (min-width: 1400px) {
                display: block;
            }
            .catalog-deco {
                -webkit-clip-path: url(#svgPath);
                clip-path: url(#svgPath);
                height: 1190px;
                z-index: -1;
                -webkit-transform: rotate(-19deg);
                -ms-transform: rotate(-19deg);
                transform: rotate(-9deg);
                position: absolute;
                top: -60px;
                left: -470px;
            }
        }
        .catalog-decoTwo-bloc {
            position: absolute;
            top: 0;
            right: 0px;
            width: 1173px;
            height: 970px;
            overflow: hidden;
            z-index: -1;
            display: none;
            @media (min-width: 1400px) {
                display: block;
            }
            .catalog-decoTwo {
                height: 1190px;
                -webkit-clip-path: url(#svgPath2);
                clip-path: url(#svgPath2);
                z-index: -1;
                position: absolute;
                top: -30px;
                right: -1150px;
            }
        }
        > .container {
            background-color: #fff;
            border-radius: 40px;
            padding: 50px 35px 0px 35px;
            max-width: 1400px;
            position: relative;
            z-index: 1;
            -webkit-box-shadow: 0 0 22px 0 rgba(0,0,0,.31);
            box-shadow: 0 0 22px 0 rgba(0,0,0,.31);
            margin: 0 auto 40px;
        }
    }
}

.catalogProductDetail {
    .content {
        overflow: hidden;
        padding: 40px 0;
        > .container {
            background-color: #fff;
            border-radius: 40px;
            padding: 50px 15px 0px 15px;
            position: relative;
            z-index: 1;
            -webkit-box-shadow: 0 0 22px 0 rgba(0,0,0,.31);
            box-shadow: 0 0 22px 0 rgba(0,0,0,.31);
            margin: 0 auto 40px;
            @media (min-width: 1400px) {
                max-width: 1400px;
                padding: 50px 35px 0px 35px;
            }
            &::before {
                content: "";
                width: 235px;
                height: 863px;
                position: absolute;
                top: 50px;
                left: calc(100% + 70px);
                background-image: url(/images/ficheproduit/png/fiche-produit-deco01.png);
            }
            &::after {
                content: "";
                width: 180px;
                height: 710px;
                position: absolute;
                bottom: 50px;
                right: calc(100% + 70px);
                background-image: url(/images/ficheproduit/png/fiche-produit-deco02.png);
            }
        }
    }
}

	/** RESPONSIVE **/
//768px
// Small devices (landscape phones, 34em and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 48em and up)
@include media-breakpoint-up(md) {
}

// Large devices (desktops, 62em and up)
@include media-breakpoint-up(lg) {
}

// Extra large devices (large desktops, 75em and up)
@include media-breakpoint-up(xl) {
}
