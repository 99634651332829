/*	--------------------------------------------------
	--------------------------------------------------
		méthodes de publication:
	communs, actualités, articles, événements
--------------------------------------------------
--------------------------------------------------*/

/* media (bootstrap) : présentation famille, header news detail, header event detail, structure bloc&image emajine */
.media{
    flex-wrap: wrap;
}
.media-body{}
@include media-breakpoint-up(sm) {
    .media{
        flex-wrap: nowrap;
    }
    .media-img{
        max-width: 25%;
    }
}
.template-homepage {
    .container {
        position: relative;
        .template-homepage--imgdecoOne, .template-homepage--imgdecoTwo {
            position: absolute;
            top: -340px;
            right: calc(100% - 450px);
            width: 1300px;
            z-index: -1;
            display: none;
            @media (min-width: 1500px) {
                display: block;
            }
            .template-homepage--imgdecoMask {
                clip-path: url(#svgPath);
                position: relative;
                height: 1190px;
                // img {
                //     position: absolute;
                //     top: 50%;
                //     left: 50%;
                //     transform: translate(-50%, -50%);
                // }
            }
        }
        .template-homepage--imgdecoOne {
            &::before {
                content: "\e920";
                font-family: icomoon;
                position: absolute;
                z-index: 9999;
                right: calc(100% - 1120px);
                bottom: 50px;
                font-size: 70rem;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 0;
                color: $secondary;
                opacity: 0;
                transform: translateY(-50px);
                transition: opacity 1s, transform 1s;
                transition-delay: 1s;
            }
            &.aos-animate {
                &::before {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
        .template-homepage--imgdecoTwo {
            left: calc(100% - 270px);
            right: auto;
            top: -100px;
            .template-homepage--imgdecoMask {
                clip-path: url(#svgPath2);
            }
            &::before {
                content: "";
                width: 961px;
                height: 838px;
                position: absolute;
                top: 210px;
                left: 280px;
                background: $primary;
                background: -webkit-gradient(linear,left top,left bottom,from($primary),to($secondary));
                background: -o-linear-gradient(top,$primary 0,$secondary 100%);
                background: linear-gradient(180deg,$primary 0,$secondary);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$primary",endColorstr="$secondary",GradientType=0);
                -webkit-clip-path: url(#svgPath2-before);
                clip-path: url(#svgPath2-before);
                opacity: 0;
                transform: translateY(50px);
                transition: opacity 1s, transform 1s;
                transition-delay: 1.5s;
            }
            &.aos-animate {
                &::before {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }
    .template-homepage--searchform {
        margin: 10px 0 140px 0;
        @media (min-width: 992px) {
            padding: 0 125px;
            margin: 80px 0 90px 0;
        }
        @media (min-width: 1500px) {
            padding: 0 55px;
            margin: 80px 0 140px 0;
        }
    }
    .template-homepage--principaltitle {
        display: flex;
        justify-content: center;
        @media (min-width: 1200px) {
            justify-content: flex-start;
        }
        @media (min-width: 1500px) {
            justify-content: center;
        }
        h2 {
            font-size: 2.9rem;
            line-height: 34px;
            color: $fourth;
            position: relative;
            margin: 0;
            text-align: center;
            @include media-breakpoint-up(lg) {
                text-align: left;
            }
            @media (min-width: 1500px) {
                margin: 0 0 0 50px;
            }
            p {
                margin: 0;
                position: relative;
                strong {
                    font-weight: 800;
                }
                &::before {
                    content: "\e91b";
                    font-family: icomoon;
                    position: absolute;
                    bottom: -50px;
                    font-weight: 800;
                    transform: rotate(72deg);
                    font-size: 2rem;
                    left: 25px;
                }
            }
            &::before {
                content: "\e91e";
                font-family: icomoon;
                font-weight: 800;
                color: $sixth;
                position: absolute;
                top: -20px;
                right: 10px;
            }
            &::after {
                content: "\e916";
                font-family: icomoon;
                font-weight: 800;
                font-size: 2rem;
                position: absolute;
                bottom: -30px;
                right: -10px;
                transform: rotate(-15deg);
            }
        }
    }
    .template-homepage--loc {
        margin: 70px -15px 0 -15px;
        @include media-breakpoint-up(lg) {
            margin: 90px -15px 0 -15px;
        }
        @media (min-width: 1500px) {
            margin: 240px -15px 0 -15px;
        }
        .template-homepage--loc-list {
            padding: 0 0 50px 0;
            @include media-breakpoint-up(lg) {
                padding: 0;
            }
            .bienresum {
                margin: 0 15px;
            }
            .slick-arrow {
                top: initial;
                bottom: 0;
                right: 20px;
                left: initial;
                opacity: 1;
                width: 22px;
                height: 52px;
                transform: translateY(0);
                @include media-breakpoint-up(lg) {
                    bottom: 80px;
                    right: -25px;
                }
                &.slick-prev {
                    transform: rotate(180deg);
                    right: 60px;
                    @include media-breakpoint-up(lg) {
                        right: initial;
                        left: -25px;
                    }
                }
                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-image: url(/images/slider-arrow.png);
                    opacity: 1;
                    top: 0;
                    left: 0;
                }
            }
        }
        .template-homepage--loc-link {
            text-align: center;
            @include media-breakpoint-up(lg) {
                text-align: left;
            }
        }
    }
    .template-homepage--presentation {
        margin: 60px 0 40px;
        position: relative;
        z-index: 1;
        @include media-breakpoint-up(lg) {
            margin: 150px 0 40px 0;
        }
        @media (min-width: 1300px) {
            &::before {
                content: "";
                position: absolute;
                font-size: 60rem;
                width: 1110px;
                height: 939px;
                background-image: url(/images/packimmo-presentation-bg.png);
                background-size: contain;
                background-position: center;
                top: -90px;
                left: -50px;
                z-index: 1;
            }
            &::after {
                content: "";
                width: 984px;
                height: 823px;
                background: $primary;
                background: -webkit-gradient(linear,left top,left bottom,from($primary),to($secondary));
                background: -o-linear-gradient(top,$primary 0,$secondary 100%);
                background: linear-gradient(180deg,$primary 0,$secondary);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$primary",endColorstr="$secondary",GradientType=0);
                position: absolute;
                top: -70px;
                left: 70px;
                clip-path: url(#template-homepage--presentationMask);
                z-index: 0;
            }
        }
        &.template-homepage--presentation-last-minutes {
            @include media-breakpoint-up(lg) {
                margin-top: 80px;
            }
            .template-homepage--loc-list-item-infos-resumbloc {
                > p {
                    color: $color05;
                }
            }
        }
        * {
            position: relative;
            z-index: 2;
        }
        h1 {
            font-size: 2.9rem;
            line-height: 34px;
            color: $third;
            position: relative;
            margin: 0 0 40px 0;
            font-weight: 300;
            display: inline-block;
            @include media-breakpoint-up(lg) {
                margin: 30px 0 0 60px;
            }
            p {
                position: relative;
                strong {
                    font-weight: 700;
                }
                &::before {
                    content: "\e916";
                    font-family: icomoon;
                    position: absolute;
                    right: 0;
                    transform: rotate(65deg);
                    font-size: 1.5rem;
                    font-weight: 800;
                    color: $fifth;
                }
            }
            &::before {
                content: "\e91b";
                font-family: icomoon;
                position: absolute;
                top: -60px;
                left: 50px;
                font-size: 2rem;
                font-weight: 800;
                color: $fourth;
                transform: rotate(75deg);
            }
            &::after {
                content: "\e91e";
                font-family: icomoon;
                position: absolute;
                bottom: -30px;
                left: 20px;
                font-weight: 800;
                font-size: 2rem;
                color: $fourth;
            }
        }
        .template-homepage--presentation-text {
            display: flex;
            flex-direction: column;
            p {
                color: $third;
                margin: 40px 0 0 0;
                @include media-breakpoint-up(lg) {
                    margin: 0 0 0 150px;
                }
            }
            a {
                margin: 40px 0 30px auto;
                @include media-breakpoint-up(lg) {
                    margin: 40px 0 0 auto;
                }
            }
        }
        .template-homepage--presentation-imgOne {
            @media (min-width: 1200px) {
                margin: -190px 0 0 0;
                img {
                    clip-path: url(#template-homepage--presentation-imgOneMask);
                    min-height: 480px;
                    width: initial;
                }
            }
        }
        .template-homepage--presentation-imgTwo {
            @media (min-width: 1200px) {
                transform: translateX(-70px);
                img {
                    clip-path: url(#template-homepage--presentation-imgTwoMask);
                    min-height: 340px;
                    width: initial;
                }
            }
        }
    }
    .template-homepage--avis {
        background-color: $fifth;
        padding: 80px 0 50px 0;
        @media (min-width: 1200px) {
            margin: -140px 0 0 0;
        }
        .template-homepage--avis-img {
            position: relative;
            @media (min-width: 1200px) {
                .template-homepage--avis-img {
                    position: absolute;
                    &.imgOne {
                        top: -140px;
                        width: 479px;
                        height: 455px;
                        right: 70px;
                        img {
                            clip-path: url(#template-homepage--avis-imgOneMask);
                            min-height: 455px;
                        }
                    }
                    &.imgTwo {
                        bottom: 0;
                        background-color: #fff;
                        padding: 0;
                        clip-path: url(#template-homepage--avis-imgTwoMask);
                        width: 293px;
                        height: 207px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
        .template-homepage--avis-text {
            padding: 40px 15px 0 15px;
            p {
                color: #fff;
            }
        }
        .template-homepage--avis-title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            h2 {
                font-size: 2.9rem;
                line-height: 34px;
                color: #fff;
                position: relative;
                margin: 0 0 0 60px;
                font-weight: 300;
                display: inline-block;
                p {
                    position: relative;
                    &::before {
                        content: "\e916";
                        font-family: icomoon;
                        position: absolute;
                        right: 0;
                        -webkit-transform: rotate(65deg);
                        -ms-transform: rotate(65deg);
                        transform: rotate(80deg);
                        font-size: 1.5rem;
                        font-weight: 800;
                        color: #fff;
                        top: 20px;
                        @include media-breakpoint-up(lg) {
                            right: -20px;
                        }
                    }
                    strong {
                        font-weight: 700;
                    }
                }
                &::before {
                    content: "\e91e";
                    font-family: icomoon;
                    position: absolute;
                    top: -20px;
                    right: 20px;
                    font-weight: 800;
                    font-size: 2rem;
                    color: #fff;
                }
                &::after {
                    content: "\e91b";
                    font-family: icomoon;
                    position: absolute;
                    bottom: 30px;
                    left: -54px;
                    font-size: 2rem;
                    font-weight: 800;
                    color: #fff;
                    -webkit-transform: rotate(75deg);
                    -ms-transform: rotate(75deg);
                    transform: rotate(25deg);
                }
            }
            a {
                position: relative;
                margin: 0 0 0 70px;
                z-index: 1;
            }
        }
    }
    .template-homepage--actu {
        background-color: $sixth;
        padding: 20px 0 10px 0;
        .template-homepage--actu-title {
            h2 {
                font-size: 2.9rem;
                line-height: 34px;
                color: #fff;
                position: relative;
                margin: 0;
                font-weight: 300;
                display: inline-block;
                p {
                    position: relative;
                    &::before {
                        content: "\e916";
                        font-family: icomoon;
                        position: absolute;
                        right: 30px;
                        -webkit-transform: rotate(65deg);
                        -ms-transform: rotate(65deg);
                        transform: rotate(80deg);
                        font-size: 1.5rem;
                        font-weight: 800;
                        color: #fff;
                        top: -10px;
                    }
                    strong {
                        font-weight: 700;
                    }
                }
                &::before {
                    content: "\e91e";
                    font-family: icomoon;
                    position: absolute;
                    top: -20px;
                    left: -50px;
                    font-weight: 800;
                    font-size: 2rem;
                    color: #fff;
                }
                &::after {
                    content: "\e91b";
                    font-family: icomoon;
                    position: absolute;
                    bottom: 30px;
                    right: -60px;
                    font-size: 2rem;
                    font-weight: 800;
                    color: #fff;
                    -webkit-transform: rotate(105deg);
                    -ms-transform: rotate(105deg);
                    transform: rotate(105deg);
                }
            }
        }
        .template-homepage--actu-text {
            p {
                color: #fff;
                margin: 0;
            }
        }
        .template-homepage--actu-link {
            position: relative;
            z-index: 1;
        }
    }
}

// ALERTE MAIL
.alert--form-search, .alert--form-infos {
    legend {
        padding: 0 15px;
        text-transform: uppercase;
        margin: 30px 0 0 0 !important;
        display: block;
    }
}

/** RESPONSIVE **/

// Small devices (landscape phones, 34em and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 48em and up)
@include media-breakpoint-up(md) {
}

// Large devices (desktops, 62em and up)
@include media-breakpoint-up(lg) {
}

// Extra large devices (large desktops, 75em and up)
@include media-breakpoint-up(xl) {
}
