/*	--------------------------------------------------
	--------------------------------------------------
		Widgets :
	propriétés générales, texte, actu, rss,
	banniere, calendrier, raccourcis, météo, sondage,
	recommander le site, newsletter, publicité

--------------------------------------------------
--------------------------------------------------*/

/* commons */
.box {}
.box--title {}

//no masque les titres des widget avec la classe "no-title"
.no-title .box--title{display: none}

/* text (/boxes/text/box_text.html) */
.box-txt {}

/* shortcuts header (/boxes/shortcut/header_shortcut.html) */
.box-header-links {}
.box-header-links--list {}
.box-header-links--item {}
.box-header-links--link{}

/* shortcuts aside (/boxes/shortcut/tools_shortcut.html) */
.box-aside-links {}
.box-aside-links--list {}
.box-aside-links--item {}
.box-aside-links--link {}

/* shortcuts footer (/boxes/shortcut/footer_shortcut.html) */
.box-footer-links {}
.box-footer-links--list {}
.box-footer-links--item {}
.box-footer-links--link {}

/* last elements (/boxes/last_elements/last_elements.html) */
.box-last-elts {}
.box-last-elts--list {}
.box-last-elts--item {}
.box-last-elts--article {}
.box-last-elts--title {}
.box-last-elts--date {}
.box-last-elts--img {}
.box-last-elts--desc {}
.box-last-elts--link{}

/* newsletter (/newsletters/box_newsletters.html) */
.box-nl {}
.box-nl--desc {}
.box-nl--form {}
.box-nl--label {}
.box-nl--input {}
.box-nl--submit {}


/* search (/search/box_search.html) */
.box-search {}
.box-search--form {}
.box-search--label {}
.box-search--input {}
.box-search--submit {}
.box-search--advanced {}

/* box-social (/z-templates/social.tmpl.html) */
.box-social {}
.box-social--list{margin-bottom: 0;}
.box-social--item {
    display: inline-block;
    vertical-align: middle;
}
.box-social--link {
	color: $primary;
	width: 30px;
	height: 30px;
	text-align: center;
	padding-top: 7px;
	display: block;
	text-decoration: none;
}
.box-social--facebook {}
.box-social--twitter {}
.box-social--google {}

.header--boxes-name {
    margin: 0;
    flex-grow: 1;
    width: 100%;
    @include media-breakpoint-up(lg) {
        width: initial;
    }
    .box-coordinates--address {
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-up(lg) {
            justify-content: flex-start;
        }
        p {
            margin: 0;
            color: #fff;
            font-size: 0.8rem;
            @include media-breakpoint-up(lg) {
                font-size: 1rem;
            }
        }
        span {
            color: #fff;
            margin: 0 4px 2px 0;
            font-size: 1.2rem;
        }
    }
}

.box-login {
    margin: 0 0 0 15px;
    display: none;
    @include media-breakpoint-up(lg) {
        display: block;
    }
    a {
        color: #fff;
        padding: 0;
        span {
            font-size: 1.5rem;
            transition: transform .1s;
        }
        &:hover, &:active, &:focus {
            background-color: transparent;
            color: #fff;
            span {
                transform: scale(1.2);
            }
        }
    }
}

.box-cart {
    display: none;
    @include media-breakpoint-up(lg) {
        display: block;
    }
    p {
        margin: 0;
        a {
            display: flex;
            align-items: center;
            color: #fff;
            span {
                font-size: 1.5rem;
                color: $third;
                transition: transform .1s;
            }
            &:hover, &:active, &:focus {
                text-decoration: none;
                span {
                    transform: scale(1.2);
                }
            }
        }
    }
}
.header--boxes-numtel {
    position: relative;
    width: 127px;
    height: 21px;
    margin: 0;
    @include media-breakpoint-up(lg) {
        margin: 0 0 0 30px;
    }
    p {
        margin: 0;
        a {
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transition: letter-spacing .3s;
            transform: translate(-50%, -50%);
            width: 100%;
            text-align: left;
            font-size: 0.8rem;
            @include media-breakpoint-up(lg) {
                font-size: 1rem;
                text-align: center;
            }
            &:hover {
                letter-spacing: 1px;
                text-decoration: none;
            }
        }
    }
}
footer {
    .header--boxes-numtel {
        width: 50%;
        margin: 0 0 10px 0;
        @include media-breakpoint-up(lg) {
            margin: 0 12px 0 0;
            width: 133px;
        }
        a {
            color: #000;
            font-weight: 600;
            @include media-breakpoint-up(lg) {
                padding: 0 10px 0 0;
                &::after {
                    content: "";
                    position: absolute;
                    top: 4px;
                    right: 0;
                    height: 15px;
                    width: 2px;
                    background-color: #000;
                }
            }
        }
    }
}
.header--boxes-rs {
    margin: 0;
    width: 50%;
    @include media-breakpoint-up(lg) {
        margin: 0 0 0 30px;
        width: initial;
    }
}
footer {
    .header--boxes-rs {
        margin: 0;
        ul {
            justify-content: center;
            @include media-breakpoint-up(lg) {
                justify-content: flex-start;
            }
            li {
                a {
                    margin: 0 3px;
                    span {
                        font-size: 2rem;
                        &::before {
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}
ul.box-social--list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include media-breakpoint-up(lg) {
        justify-content: flex-start;
    }
    li {
        a {
            padding: 0;
            color: #fff;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform .1s;
            &:hover {
                text-decoration: none;
                transform: scale(1.2);
            }
            span {
                &::before {
                    color: #fff;
                }
            }
        }
    }
}
.header--boxes-contact {
    @include media-breakpoint-up(lg) {
        margin: 0 0 0 30px;
    }
    ul {
        margin: 0;
        li {
            position: relative;
            width: 77px;
            height: 21px;
            a {
                color: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transition: letter-spacing .3s;
                transform: translate(-50%, -50%);
                width: 100%;
                text-align: left;
                font-size: 0.8rem;
                @include media-breakpoint-up(lg) {
                    font-size: 1rem;
                    text-align: center;
                }
                &:hover {
                    letter-spacing: 1px;
                    text-decoration: none;
                }
            }
        }
    }
}
.footer-boxes-logofnaim {
    margin: 10px 0;
    @include media-breakpoint-up(lg) {
        margin: 0 0 0 20px;
    }
    p {
        margin: 0;
    }
}
.footer--boxes-adresse {
    margin: 0;
    p {
        margin: 0;
    }
}
.footer--boxes-button {
    margin: 0 0 0 20px;
    ul {
        margin: 0;
    }
}
.footer--boxes-link {
    ul {
        margin: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
        li {
            a {
                color: #000;
                font-weight: 600;
                position: relative;
                @include media-breakpoint-up(lg) {
                    padding: 0 15px 0 0;
                    margin: 0 15px 0 0;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 4px;
                        right: 0;
                        height: 15px;
                        width: 2px;
                        background-color: #000;
                    }
                }
            }
        }
    }
}
/** RESPONSIVE **/

// Small devices (landscape phones, 34em and up)
@include media-breakpoint-up(sm) {

}

// Medium devices (tablets, 48em and up)
@include media-breakpoint-up(md) {

}

// Large devices (desktops, 62em and up)
@include media-breakpoint-up(lg) {

}

// Extra large devices (large desktops, 75em and up)
@include media-breakpoint-up(xl) {

}
