/*	--------------------------------------------------
	--------------------------------------------------
		Catalogue et ses widgets
--------------------------------------------------
--------------------------------------------------*/


// MOTEUR DE RECHERCHE
/* box search (/catalog/search/box_search.html) */
@mixin placeholderInputSearch () {
    // IE / Edge
    &:-ms-input-placeholder {
        color: $third;
	}
    // Chrome / Safari / Opera
    &::-webkit-input-placeholder {
        color: $third;
	}
    // FF
    &::placeholder {
        color: $third;
	}
}

@-webkit-keyframes hvr-icon-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes hvr-icon-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
.box-cat-search {
    -webkit-box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.31);
    -moz-box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.31);
    box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.31);
    background-color: #fff;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 50px;
    .box-cat-search--content {
        form {
            display: flex;
            flex-direction: column;
            @include media-breakpoint-up(lg) {;
                flex-direction: row;
            }
            .box-cat-search--wrapper {
                flex-grow: 1;
                position: relative;
                .box-cat-search--wrapper-bloc {
                    position: relative;
                    padding: 20px 15px;
                    @include media-breakpoint-up(lg) {
                        padding: 30px 70px 30px 30px;
                    }
                    .box-cat-search--collapse {
                        padding: 20px 0 0 0;
                    }
                    .box-cat-search--fields, .box-cat-search--collapse-content {
                        position: relative;
                        display: flex;
                        flex-wrap: wrap;
                        @media (min-width: 1200px) {
                            flex-wrap: nowrap;
                        }
                        > div {
                            flex-grow: 1;
                            margin: 0 0 10px 0;
                            width: 100%;
                            @media (min-width: 1200px) {
                                margin: 0 6px;
                                width: initial;
                            }
                            &.form-group_check {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 0 !important;
                                label {
                                    color: $third;
                                }
                            }
                            &.minWidth {
                                min-width: 172px;
                            }
                        }
                        .dropdown {
                            position: relative;
                            &::before {
                                content: "\e608";
                                font-family: icomoon;
                                position: absolute;
                                top: 12px;
                                right: 14px;
                                z-index: 1;
                                -webkit-transform: rotate(90deg);
                                -ms-transform: rotate(90deg);
                                transform: rotate(90deg);
                                font-size: 1.5rem;
                                color: $third;
                                line-height: 1;
                                font-weight: 800;
                            }
                            button{
                                border: 2px solid $third;
                                border-radius: 30px;
                                color: $third;
                                position: relative;
                                z-index: 2;
                                background-color: transparent;
                                padding: 10px 44px 10px 20px;
                                font-size: 1rem;
                                &:focus {
                                    box-shadow: none;
                                }
                                &::after {
                                    display: none !important;
                                }
                            }
                            .dropdown-menu {
                                border: transparent;
                                border-radius: 0;
                                background-color: #fff;
                                margin: 0;
                                .custom-control.custom-checkbox {
                                    > input {
                                        display: none;
                                    }
                                    label {
                                        color: $third;
                                        display: flex;
                                        align-items: center;
                                        span.checkbox-label {
                                            display: none;
                                        }
                                        &:before {
                                            display: block;
                                            width: 1rem;
                                            height: 1rem;
                                            content: "";
                                            margin: 0 7px 0 0;
                                        }
                                        &:after {
                                            position: absolute;
                                        }
                                    }
                                }
                            }
                        }
                        .form-group_date {
                            display: flex;
                            flex-direction: column;
                            label {
                                margin: 0;
                                color: $third
                            }
                            .datepicker-container {
                                input {
                                    border: transparent;
                                    border-radius: 0;
                                    color: $third;
                                    font-size: 1rem;
                                    font-weight: 600;
                                    position: relative;
                                    z-index: 2;
                                    background-color: transparent;
                                    padding: 0;
                                   @include placeholderInputSearch;
                                   &:focus {
                                       box-shadow: none;
                                   }
                               }
                            }
                            &::after {
                                color: $third;
                                bottom: 6px;
                                font-size: 1.5rem;
                                top: initial;
                                transform: translateY(0);
                            }
                        }
                    }
                }
                button.btn.box-cat-search--more {
                    margin: 40px 40px 0 auto;
                    font-weight: 700;
                    position: relative;
                    color: $third;
                    font-size: 1.1rem;
                    padding: 10px 10px 0 0px;
                    position: absolute;
                    right: 0;
                    @include media-breakpoint-up(lg) {
                        color: $primary;
                        margin: 0 90px 0 auto;
                    }
                    &::before {
                        content: "+";
                        position: absolute;
                        top: -24px;
                        right: -41px;
                        background-color: $third;
                        border-radius: 50%;
                        width: 50px;
                        height: 50px;
                        color: #fff;
                        font-size: 3.2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 400;
                        transition: transform .5s;
                        @include media-breakpoint-up(lg) {
                            background-color: $primary;
                        }
                    }
                    &:hover {
                        &::before {
                            transform: rotate(180deg);
                        }
                    }
                }
                input {
                    border: 2px solid $third;
                    border-radius: 30px;
                    color: $third;
                    position: relative;
                    z-index: 2;
                    background-color: transparent;
                    padding: 10px 44px 10px 20px;
                    @include placeholderInputSearch;
                    &:focus {
                        box-shadow: none;
                    }
                }
                .form-group {
                    padding: 0;
                    margin: 0;
                    &::after {
                        color: $third;
                    }
                    &.form-group_check {
                        label {
                            color: $third;
                        }
                    }
                    &.button-footer {
                        display: flex;
                        justify-content: flex-end;
                        position: absolute;
                        width: 100%;
                        .box-cat-search--more {
                            position: relative!important;
                            right: auto!important;
                            margin: 0 90px 0 0;
                        }
                        a {
                            color: $third;
                            font-size: 1rem;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            font-weight: 700;
                            padding: 10px 0 0 0;
                            margin: 0 30px 0 0;
                            &::before {
                                content: "\e910";
                                font-family: icomoon;
                                margin: 0 9px 0 0;
                            }
                            &:hover {
                                text-decoration: none;
                                &::before {
                                    -webkit-animation-name: hvr-icon-buzz-out;
                                    animation-name: hvr-icon-buzz-out;
                                    -webkit-animation-duration: 0.75s;
                                    animation-duration: 0.75s;
                                    -webkit-animation-timing-function: linear;
                                    animation-timing-function: linear;
                                    -webkit-animation-iteration-count: 1;
                                    animation-iteration-count: 1;
                                }
                            }
                        }
                    }
                }
            }
            .box-cat-search--submit-container {
                margin: 0;
                button, button:active {
                    width: 100%;
                    border: transparent !important;
                    background-color: $secondary !important;
                    color: #fff;
                    padding: 0;
                    @include media-breakpoint-up(lg) {
                        height: 100%;
                    }
                    span {
                        font-size: 4rem;
                    }
                }
            }
        }
    }
}

.ui-datepicker {
    background-color: #fff;
    padding: 10px;
    width: 270px;
    max-width: 100%;
    display: none;
    &.active {
        display: block;
    }
    .ui-datepicker-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        a {
            font-size: 13px;
            background-color: $primary;
            padding: 2px 10px;
            border-radius: 4px;
            cursor: pointer;
            color: #fff;
            span {
                display: none;
            }
            &:hover, &:focus, &:active {
                color: #fff !important;
            }
            &.ui-datepicker-next{
                &::after {
                    content: "\e608";
                    font-family: 'icomoon';
                    font-size: 1.1rem;
                }
            }
            &.ui-datepicker-prev{
                &::before {
                    content: "\e607";
                    font-family: 'icomoon';
                    font-size: 1.1rem;
                }
            }
        }
        .ui-datepicker-title {
            width: 100%;
            text-align: center;
            font-size: 18px;
            margin: 7px 0;
        }
    }
    table.ui-datepicker-calendar {
        width: 100%;
        th {
            text-align: center;
        }
        td {
            text-align: center;
            padding: 5px;
            a {
                background-color: $primary;
                color: #fff;
                margin: 1px;
                display: inline-block;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background-color .3s;
                &:hover, &:active, &:focus {
                    text-decoration: none;
                    background-color: #c1c1c1;
                }
            }
        }
    }
    .prod-dispo--legend {
        p {
            text-align: center;
            margin: 5px 0 0 0;
            font-size: 13px;
            &.legend-item.legend-item-enabled {
                span.legend-square {
                    width: 10px;
                    height: 10px;
                    background-color: $primary;
                    display: inline-block;
                    margin: 0 5px 0 0;
                }
            }
        }
    }
}
.box-cat-search--nav {
    display: flex;
    border: transparent;
    border-top-left-radius: 25px;
    overflow: hidden;
    border-top-right-radius: 25px;
    background-color: #fff;
    flex-direction: column;
    @media (min-width: 1200px) {
        flex-direction: row;
        > a:first-child.active {
            border-top-right-radius: 25px !important;
            border-bottom-right-radius: 25px !important;
        }
        > a:nth-child(2).active,
        > a:nth-child(3).active {
            border-radius: 25px !important;
        }

    }
}
.box-cat-search--nav--link {
    text-align: center;
    border: transparent;
    background: transparent;
    color: #d3d4d6;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 !important;
    padding: 15px 40px;
    border: transparent !important;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    width: 100%;
    transition: color .5s;
    &:hover, &:focus{
        color: $third;
    }
    &.active {
        color: #fff!important;
        display: flex;
        font-weight: 700;
        background-color: $third !important;
    }
    @media (min-width: 1200px) {
        width: initial;
        font-size: 1.1rem;
        padding: 7px 40px;
    }
    @media (min-width: 1500px) {
        font-size: 1.4rem;
    }
}

.btn-select{
    text-align: left;
    padding-right: 28px;
    position: relative;
    color:red;
    &:after{
        content: '\e91b';
        @include icomoon;
        position: absolute;
        right: 10px;
        border: 0;
        top: 50%;
        height: 13px;
        width: 9px;
        transform: translateY(-50%);
        font-size: 12px;
    }
}
.dropdown-select{
    padding: 15px;
    min-width: 100%;
    margin: 0.1rem 0 0;
    .custom-control-label{
        text-transform: lowercase;
        white-space: nowrap;
        &:first-letter{text-transform: uppercase;}
    }
}
.box-cat-search--more{
    background: transparent;
    color:red;
    font-size: 14px;
    display: flex;
    align-items: center;
    box-shadow: none;
    padding: 0;
    margin-bottom: 15px;
    border:none;
    margin: 10px 0 0 0;
    &:after{
        display: none;
    }
    &:focus{outline:none; border:none; box-shadow: none;}
    &:hover, &:focus{
        color:red;
    }
}
.box-cat-search--more-icon{
    color: red;
    font-size: 20px;
    padding-right: 5px;
    font-weight: bold;
}

.box-cat-search--submit{
    width: 100%;
    height: 80px;
    border: transparent;
    padding: 0;
    cursor: pointer;
    background-color: $third;
    color: #fff;
    z-index: 1;
    font-weight: 700;
    border-radius: 40px;
    transition: transform .3s;
    @media (min-width: 1200px) {
        position: absolute;
        right: -110px;
        border-radius: 0;
        bottom: 0;
        background-color: transparent;
        width: 171px;
        height: 194px;
        &::before {
            content: "\e918";
            font-family: icomoon;
            position: absolute;
            top: 22px;
            left: 0;
            font-size: 12rem;
            color: $third;
            z-index: -1;
            font-weight: initial;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(-55deg);
        }
        &::after {
            font-family: icomoon;
            content: "\e91c";
            font-size: 4rem;
            font-weight: initial;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 50px;
        }
    }
    &:hover, &:focus, &:active{
        outline: none;
        transform: scale(1.05);
    }

}

.form-group_check {
    padding: 8px 0 0!important;
}
.form-group_check .checkbox-value {
    display: none;
}

.form-group_surface{
    position: relative;
    &:after{
        content: "m²";
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        right:24px;
        font-size: 14px;
        color:$body-color;
    }
    .form-control{padding-right: 30px;}
}

.form-group_price{
    position: relative;
    &:after{
        content: "€";
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        right:24px;
        font-size: 14px;
        color:$body-color;
    }
    .form-control{padding-right: 20px;}
}

.form-group_date{
    position: relative;
    &:after{
        content: "\e902";
        @include icomoon;
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        right:0;
        font-size: 18px;
        color:$body-color;
        pointer-events: none;
        @include media-breakpoint-up(lg) {
            right:24px;
        }
    }
    .form-control{padding-right: 32px;}
}
.bienresum {
    -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,.31);
    box-shadow: 0 0 15px 0 rgba(0,0,0,.31);
    border-radius: 90px;
    overflow: hidden;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    .template-homepage--loc-list-item-img {
        height: 240px;
        transition: height .3s;
        div {
            height: 100%;
        }
    }
    .template-homepage--loc-list-item-infosbloc {
        .template-homepage--loc-list-item-infos {
            background-color: #fff;
            .template-homepage--loc-list-item-infos-titlebloc {
                display: flex;
                margin: 0;
                justify-content: space-between;
                .template-homepage--loc-list-item-infos-title {
                    width: 75%;
                    p.ref {
                        color: #707070;
                        margin: 15px 15px 0 15px;
                        font-weight: 600;
                    }
                    h3.product-resum--infos--title {
                        color: #000;
                        font-size: 1rem;
                        margin: 5px 15px 0px 15px;
                        font-weight: 600;
                    }
                }
                .template-homepage--loc-list-item-infos-list {
                    position: relative;
                    width: 25%;
                    min-width: 25%;
                    ul {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 0;
                        margin: 0;
                        width: 100%;
                        li {
                            list-style-type: none;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            line-height: 1.2;
                            background-color: $primary;
                            color: #fff;
                            padding: 10px 5px;
                            font-size: 0.7rem;
                            span {
                                font-size: 1.2rem;
                                font-weight: 600;
                                line-height: 1;
                            }
                            &.coeur {
                                background-color: $fifth;
                            }
                        }
                    }
                }
            }
            .template-homepage--loc-list-item-infos-resumbloc {
                display: flex;
                justify-content: space-between;
                p {
                    height: 130px;
                    overflow: hidden;
                    line-height: 1.2;
                    color: #000;
                    font-size: 0.9rem;
                    margin: 10px 0 10px 15px;
                    position: relative;
                    width: 75%;
                    &::after {
                        content: "";
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
                    }
                }
                .template-homepage--loc-list-item-infos-resumbloc-list {
                    position: relative;
                    width: 25%;
                    min-width: 25%;
                    ul {
                        display: flex;
                        flex-direction: column;
                        margin: 0;
                        padding: 0 5px;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        height: 100%;
                        li {
                            list-style-type: none;
                            display: flex;
                            flex-direction: column;
                            color: #000;
                            margin: 6px 0;
                            span {
                                font-size: 1.8rem;
                            }
                        }
                    }
                }
            }
        }
        > p {
            text-align: center;
            background-color: $primary;
            margin: 0;
            color: #fff;
            padding: 15px;
            transition: padding .3s;
        }
    }
    &:hover, &:focus, &:active {
        text-decoration: none;
        .template-homepage--loc-list-item-img {
            height: 200px;
        }
        .template-homepage--loc-list-item-infosbloc {
            > p {
                padding: 35px 15px;
            }
        }
    }
}

// PAGE CATALOG
.cat {
    .family--desc {
        h1 {
            font-size: 2.9rem;
            line-height: 34px;
            color: $fourth;
            position: relative;
            margin: 20px 0 40px 0;
            display: inline-block;
            &:before {
                content: "\e91e";
                font-family: icomoon;
                font-weight: 800;
                color: $sixth;
                position: absolute;
                top: -20px;
                right: 10px;
            }
            &:after {
                content: "\e916";
                font-family: icomoon;
                font-weight: 800;
                font-size: 2rem;
                position: absolute;
                bottom: -30px;
                right: -10px;
                -webkit-transform: rotate(-15deg);
                -ms-transform: rotate(-15deg);
                transform: rotate(-15deg);
            }
        }
        p {
            color: $third;
            position: relative;
            &::after {
                content: "\e91b";
                font-family: icomoon;
                position: absolute;
                bottom: -60px;
                font-weight: 800;
                -webkit-transform: rotate(72deg);
                -ms-transform: rotate(72deg);
                transform: rotate(72deg);
                font-size: 2rem;
                left: 5px;
                color: $fourth;
            }
        }
    }

    form.sort-form {
        margin: 90px 0 30px;
        display: flex;
        align-items: flex-end;
        @include media-breakpoint-up(lg) {
            margin: 90px 0 0 0;
        }
        .form-group {
            margin: 0 10px 0 0;
            label {
                margin: 0 10px 0 0;
                color: $primary;
                font-weight: 600;
            }
            select {
                border-radius: 0;
                border: 1px solid $primary;
                padding: 0 5px 2px 5px;
                color: $primary;
                margin: 0 10px 0 0;
                &:focus, &:active, &:hover {
                    outline: none !important;
                    color: $primary !important;
                    box-shadow: none !important;
                }
            }
        }
        .form-radio {
            margin: 0 0px 6px 0;
            input {
                display: none;
                &:checked {
                    & + label {
                        opacity: 1;
                    }
                }
            }
            label {
                opacity: .5;
                cursor: pointer;
                background-color: $primary;
                width: 18px;
                height: 14px;
                clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                &.sort-prod--label_down {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .cat--item {
        list-style-type: none;
        @include media-breakpoint-up(lg) {
            padding: 0 25px;
        }
        .bienresum {
            display: block;
            margin: 0 0 50px 0 !important;
            .template-homepage--loc-list-item-infosbloc {
                .template-homepage--loc-list-item-infos {
                    .template-homepage--loc-list-item-infos-titlebloc {
                        .template-homepage--loc-list-item-infos-list {
                            ul {
                                li {
                                    span {
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// FICHE PRODUIT

@mixin placeholderFormDispo () {
    // IE / Edge
    &:-ms-input-placeholder {
        color: #fff;
	}
    // Chrome / Safari / Opera
    &::-webkit-input-placeholder {
        color: #fff;
	}
    // FF
    &::placeholder {
        color: #fff;
	}
}
.product_detail {
    .prod--btn-back {
        position: absolute;
        top: -20px;
        right: 40px;
    }
    .prod_vac--img {
        .prod_vac--img-mainCarrousel {
            .slick-arrow{
                &::before {
                    color: $primary;
                    font-weight: 800;
                    opacity: 1;
                    font-size: 1.5rem;
                }
                &.slick-prev {
                    left: 15px;
                    z-index: 1;
                }
                &.slick-next {
                    right: 15px;
                }
            }
            .item {
                height: 200px;
                margin: 0 0 20px 0;
                border-radius: 80px;
                overflow: hidden;
                @include media-breakpoint-up(lg) {
                    height: 560px;
                }
                img {
                    -o-object-fit: cover;
                    object-fit: cover;
                    font-family: "object-fit: cover;";
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .prod_vac--img-secondCarrousel {
            margin: -10px 0;
            .item {
                height: 90px;
                max-width: 100%;
                border: transparent;
                border-radius: 50px;
                overflow: hidden;
                @include media-breakpoint-up(lg) {
                    height: 90px;
                    height: 173px;
                    margin: 10px 0;
                    width: 300px !important;
                }
                img {
                    -o-object-fit: cover;
                    object-fit: cover;
                    font-family: "object-fit: cover;";
                    height: 100%;
                    width: 100%;
                    opacity: .5;
                    transition: opacity .8s;
                }
                &:hover, &:focus, &:active {
                    img {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .product_detail--infos {
        padding: 30px 0 0 0;
        p.product_detail--infos-ref {
            margin: 0;
            color: #adadad;
            font-size: .9rem;
        }
        .product_detail--infos-elementtitle {
            display: flex;
            flex-direction: column;
            @include media-breakpoint-up(lg) {
                flex-direction: row;
            }
            .product_detail--infos--title {
                line-height: 1;
                margin: 10px 10px 10px 0;
                font-weight: 700;
                color: $fourth;
                font-size: 1.7rem;
                @include media-breakpoint-up(lg) {
                    font-size: 3rem;
                }
            }
            .product_detail--infos-elementtitle-tools {
                display: flex;
                @include media-breakpoint-up(lg) {
                    padding: 20px 0 0 10px;
                }
                span.icon-printer1 {
                    font-size: 2.5rem;
                    color: $primary;
                }
                .box-sharing_prod{
                    margin-right: 8px;
                    @include media-breakpoint-up(lg) {
                        margin-left: auto;
                    }
                    .box-sharing--btn{
                        color:$primary;
                        background: #fff;
                        border-radius: 50%;
                        width: 35px; height:35px;
                        display: flex;
                        padding:0;
                        justify-content: center;align-items: center;
                        font-size: 35px;
                        &:hover, &:focus{
                            color:$primary;
                            background:#fff;
                        }
                        &:after{
                            display: none;
                        }
                    }
                    .box-sharing--link{
                        i{
                            padding-right: 8px;
                            width: 24px;
                            text-align: center;
                        }
                    }
                }
            }
        }
        .product_detail--infos-caracteristiques {
            display: flex;
            align-items: center;
            padding: 10px 0 20px 0;
            p {
                color: #000;
                margin: 0 20px 0 0;
                @include media-breakpoint-up(lg) {
                    margin: 0 40px 0 0;
                }
                span {
                    font-size: 1.7rem;
                    margin: 0 4px 0 0;
                    @include media-breakpoint-up(lg) {
                        margin: 0 14px 0 0;
                    }
                }
                img {
                    margin: 0 15px 0 0;
                }
            }
        }
        .product_detail--info-descBloc {
            border-bottom: 1px solid #d8dbea;
            padding: 0 0 30px;
            margin: 0 0 30px;
            a {
                text-decoration: underline;
                color: $fourth;
            }
        }
        .product_detail--info-desc {
            padding: 0 0 30px 0;
            font-weight: 600;
            overflow: hidden;
            opacity: 0;
            transition: height .5s;
            margin: 0 0 30px 0;
            .product_detail--info-desc-more {
                // opacity: 0;
                overflow: hidden;
                -webkit-transition: height .5s;
                -o-transition: height .5s;
                transition: height .5s;
                ul {
                    padding: 30px 0 0 0;
                    margin: 0;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    li {
                        width: 100%;
                        list-style-type: none;
                        font-weight: 600;
                        margin: 0 0 4px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        @include media-breakpoint-up(lg) {
                            width: 49%;
                        }
                        &::before {
                            content: "";
                            width: 20px;
                            height: 4px;
                            background-color: $primary;
                            margin: 0 7px 0 0;
                            border-radius: 3px;
                        }
                    }
                }
            }
        }
        .product_detail--info-equipementsList {
            border-bottom: 1px solid #d8dbea;
            padding: 0 0 30px 0;
            margin: 0 0 30px 0;
            h2 {
                color: #000;
                font-weight: 600;
                display: flex;
                align-items: center;
                margin: 0 0 30px 0;
                font-size: 1.5rem;
                @include media-breakpoint-up(lg) {
                    font-size: 2rem
                }
                &::before {
                    content: "+";
                    background-color: $primary;
                    color: #fff;
                    width: 30px;
                    min-width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    font-size: 2rem;
                    margin: 0 10px 0 0;
                }
            }
            a {
                text-decoration: underline;
                display: inline-block;
                margin: 20px 0 0 0;
                color: $fourth;
            }
            &.lesPLus {
                border-bottom: transparent;
                .product_detail--info-equipementsList-elems {
                    opacity: 1;
                }
            }
            .product_detail--info-equipementsList-elems {
                opacity: 0;
                overflow: hidden;
                transition: height .5s;
                ul {
                    padding: 0;
                    margin: 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    li {
                        width: 100%;
                        list-style-type: none;
                        font-weight: 600;
                        margin: 0 0 4px 0;
                        display: flex;
                        align-items: center;
                        @include media-breakpoint-up(lg) {
                            width: 49%;
                        }
                        &::before {
                            content: "";
                            width: 20px;
                            height: 4px;
                            background-color: $primary;
                            margin: 0 7px 0 0;
                            border-radius: 3px;
                        }
                    }
                }
            }
        }
        .product_detail-sidebarBloc {
            padding: 50px 35px;
            margin: 0 0 30px;
            border-radius: 60px;
            .prod-dispo--alert-warning {
                background-color: #fff;
                padding: 30px;
            }
            .prod-dispo--alert-sucess {
                background-color: #fff;
                padding: 30px;
            }
            &.product_detail-dispotarifs {
                background: $primary;
                background: -webkit-gradient(linear,left top,left bottom,from($primary),to($secondary));
                background: -o-linear-gradient(top,$primary 0,$secondary 100%);
                background: linear-gradient(180deg,$primary 0,$secondary);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$primary",endColorstr="$secondary",GradientType=0);
            }
            &.product_detail-interested {
                background-color: $sixth;
                button {
                    border: transparent;
                    margin: 20px 0 0;
                    background-color: $third;
                    text-align: left;
                    padding: 10px 30px!important;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    font-weight: 600;
                    cursor: pointer;
                    -webkit-transition: background-color .3s;
                    -o-transition: background-color .3s;
                    transition: background-color .3s;
                    color: #fff;
                    border-radius: 50px;
                    font-size: 1.3rem;
                    span {
                        font-size: 1.6rem;
                        margin: 0 0 0 20px;
                        transition: transform .5s;
                    }
                    &:hover, &:focus, &:active {
                        outline: none;
                        span {
                            transform: scale(1.3);
                        }
                    }
                }
            }
            h2 {
                color: #fff;
                text-transform: uppercase;
                line-height: 1;
                margin: 0 0 10px 0;
            }
            p {
                color: #fff;
                margin: 0;
                &.alert-success.alert {
                    color: #155724;
                    margin: 0 0 20px 0;
                }
                &.alert-warning {
                    color: #856404;
                    margin: 0 0 20px 0;
                }
            }
            .prod-dispo {
                p.prod-dispo--price {
                    background-color: #fff;
                    color: $primary !important;
                    font-weight: 600;
                    font-size: 1.5rem;
                    display: inline-block;
                    padding: 4px 15px;
                    line-height: 1;
                    margin: 20px 0 0 0 !important;
                }
                form {
                    display: flex;
                    flex-direction: column;
                    margin: 0;
                    padding: 20px 0 0 0;
                    .prod-dispo--btn-container {
                        a, button {
                            background-color: #000 !important;
                            color: #fff;
                            text-align: center;
                            padding: 20px 0;
                            text-transform: uppercase;
                            border: transparent;
                            &:hover, &:focus, &:active {
                                background-color: #fff !important;
                                color: #000 !important;
                            }
                            &::after, &::before {
                                display: none;
                            }
                        }
                    }
                    a {
                        background-color: #fff !important;
                        width: 100%;
                        border: transparent;
                        font-size: 1.2rem;
                        padding: 5px 20px;
                        margin: 5px 0;
                        text-align: left;
                        &:after {
                            display: none;
                        }
                    }
                    .datepicker-container {
                        position: relative;
                        &::after {
                            content: "\e902";
                            font-family: icomoon;
                            color: #fff;
                            position: absolute;
                            top: 8px;
                            right: 20px;
                            font-size: 2rem;
                        }
                        input {
                            width: 100%;
                            border: 1px solid #fff;
                            background-color: transparent;
                            color: #fff;
                            margin: 0 0 10px 0;
                            padding: 15px 60px 15px 20px;
                            font-size: 1.2rem;
                            border-radius: 50px;
                            @include placeholderFormDispo;
                            &:hover, &:focus, &:active {
                                outline: none;
                            }
                        }
                    }
                    .prod-dispo--valide-date-container {
                        button {
                            background-color: $third;
                            width: 100%;
                            font-size: 1.3rem;
                            text-align: center;
                            padding: 5px 20px;
                            color: #fff;
                            border-radius: 50px;
                            span {
                                font-size: 3rem;
                                transition: transform .5s;
                            }
                            &:hover, &:focus, &:active {
                                background-color: $third !important;
                                color: #fff !important;
                                span {
                                    transform: scale(1.3);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.product_detail-map {
    padding: 40px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
        text-align: center;
        margin: 0 0 40px 0;
        color: $fourth;
        position: relative;
        display: inline-block;
        &::before {
            content: "\e916";
            font-family: icomoon;
            font-weight: 800;
            font-size: 1.7rem;
            position: absolute;
            bottom: -20px;
            left: -40px;
            -webkit-transform: rotate(-15deg);
            -ms-transform: rotate(-15deg);
            transform: rotate(-15deg);
        }
    }
}

.product_detail--info-descBloc_2 {
    border-bottom: none!important;
}

.product_detail-dispotarifs-title {
    margin-bottom: 20px!important;
}

.product_detail-dispotarifs-img {
    max-width: 220px;
    color: $white;
    margin-top: 10px;
    text-align: center;
}

.infodpeges {
    text-transform: lowercase;
    position: relative;
    z-index: 1;
    font-size: 20px;
    cursor: pointer;
    margin-left: 15px;
    display: inline-block;
    vertical-align: middle;

    &:before {
        content: '';
        width: 24px;
        height: 24px;
        background-color: $color03;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: -1;
    }
}

// PANIER
.cart {
    header.cart-header {
        background-color: #efefef;
        padding: 6px 15px;
        margin: 20px 0 30px 0;
        ol {
            margin: 0;
            li {
                border-right: 1px solid $primary;
                text-align: center;
                &.active {
                    color: $primary;
                    font-weight: 800;
                }
                &:last-child {
                    border-right: transparent
                }
            }
        }
    }
    .cart-submit {
        .btn.btn-arrow {
            padding: 3px 50px 5px 25px !important;
        }
    }
}

#cartAuthentification {
    .cart--title {
        text-align: center;
    }
    .formulaire-tunnel {
        > h2 {
            text-align: center;
        }
    }
    input.btn-primary {
        background-color: $primary;
        color: #fff;
        padding: 10px 20px;
        &:hover, &:active, &:focus {
            background-color: $secondary !important;
            color: #fff;
        }
    }
}
#cart_order_confirmation {
    .bg-primary {
        padding: 10px 20px;
        * {
            color: #fff;
        }
        .btn-primary {
            border: 2px solid #fff;
            &:hover, &:active, &:focus {
                border: 2px solid #fff;
            }
        }
    }
}
div#payment_infos {
    > .row {
        margin: 0;
    }
}
#cart_payment_confirmation {
    .col-12.col-lg-4.bordered {
        > .bg-primary {
            padding: 20px;
            * {
                color: #fff;
            }
        }
    }
}
/* responsive*/

// Small devices (landscape phones, 34em and up)
@include media-breakpoint-up(sm) {

}

// Medium devices (tablets, 48em and up)
@include media-breakpoint-up(md) {

}

// Large devices (desktops, 62em and up)
@include media-breakpoint-up(lg) {

}

// Extra large devices (large desktops, 75em and up)
@include media-breakpoint-up(xl) {

}
