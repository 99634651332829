@charset "UTF-8";
/*	--------------------------------------------------
	--------------------------------------------------

	Project			: xxx
	Creation date	: xx/xxx
	By				: xxx/xx

	--------------------------------------------------
	--------------------------------------------------*/
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #00b0c8;
  --secondary: #00c8ac;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #00b0c8;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #006d7c;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8e9f0; }

.table-hover .table-primary:hover {
  background-color: #a3e2ec; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a3e2ec; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #b8f0e8; }

.table-hover .table-secondary:hover {
  background-color: #a3ece1; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #a3ece1; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #49e9ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 176, 200, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .form-group.error .form-control, .was-validated
.custom-select:invalid,
.custom-select.is-invalid,
.form-group.error .custom-select.form-control {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .form-group.error .form-control:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus,
  .form-group.error .custom-select.form-control:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-group.error .form-control ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip,
  .form-group.error .form-control ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .form-group.error .custom-select.form-control ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip,
  .form-group.error .custom-select.form-control ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, .form-group.error .form-check-input.form-control ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-group.error .form-check-input.form-control ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.form-group.error .form-check-input.form-control ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label, .form-group.error .custom-control-input.form-control ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before, .form-group.error .custom-control-input.form-control ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback, .form-group.error .custom-control-input.form-control ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.form-group.error .custom-control-input.form-control ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before, .form-group.error .custom-control-input.form-control:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before, .form-group.error .custom-control-input.form-control:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label, .form-group.error .custom-file-input.form-control ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before, .form-group.error .custom-file-input.form-control ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback, .form-group.error .custom-file-input.form-control ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.form-group.error .custom-file-input.form-control ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label, .form-group.error .custom-file-input.form-control:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 176, 200, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, .btn.pager_current_page:not(:disabled):not(.disabled) {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #00b0c8;
  border-color: #00b0c8; }
  .btn-primary:hover {
    color: #fff;
    background-color: #008ea2;
    border-color: #008395; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 176, 200, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #00b0c8;
    border-color: #00b0c8; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #008395;
    border-color: #007888; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 176, 200, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #00c8ac;
  border-color: #00c8ac; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #00a28b;
    border-color: #009580; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 200, 172, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #00c8ac;
    border-color: #00c8ac; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #009580;
    border-color: #008875; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 200, 172, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .btn-success.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .btn-success.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .btn-info.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .btn-info.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .btn-warning.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .btn-danger.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .btn-light.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .btn-light.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .btn-dark.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #00b0c8;
  background-color: transparent;
  background-image: none;
  border-color: #00b0c8; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #00b0c8;
    border-color: #00b0c8; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 176, 200, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #00b0c8;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #00b0c8;
    border-color: #00b0c8; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 176, 200, 0.5); }

.btn-outline-secondary {
  color: #00c8ac;
  background-color: transparent;
  background-image: none;
  border-color: #00c8ac; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #00c8ac;
    border-color: #00c8ac; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 200, 172, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #00c8ac;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #00c8ac;
    border-color: #00c8ac; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 200, 172, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark.pager_current_page:not(:disabled):not(.disabled),
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark.pager_current_page:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #00b0c8;
  background-color: transparent; }
  .btn-link:hover {
    color: #006d7c;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item.pager_current_page, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #00b0c8; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group > .btn.pager_current_page,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active,
    .btn-group-vertical > .btn.pager_current_page {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #00b0c8; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 176, 200, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #7cefff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00b0c8; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #00b0c8; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 176, 200, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 176, 200, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00b0c8; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 176, 200, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #49e9ff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(73, 233, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #49e9ff;
    box-shadow: 0 0 0 0.2rem rgba(0, 176, 200, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #49e9ff; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active, .nav-tabs .nav-link.pager_current_page,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active, .nav-pills .nav-link.pager_current_page,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #00b0c8; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active, .tab-content > .pager_current_page {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .pager_current_page > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.pager_current_page {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .pager_current_page > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.pager_current_page {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active, .breadcrumb-item.pager_current_page {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #00b0c8;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    color: #006d7c;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 176, 200, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link, .page-item.pager_current_page .page-link {
  z-index: 1;
  color: #fff;
  background-color: #00b0c8;
  border-color: #00b0c8; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge, .errorForm, .error-form {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty, .errorForm:empty, .error-form:empty {
    display: none; }

.btn .badge, .btn .errorForm, .btn .error-form {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #00b0c8; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #008395; }

.badge-secondary {
  color: #fff;
  background-color: #00c8ac; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #009580; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger, .errorForm, .error-form {
  color: #fff;
  background-color: #dc3545; }
  .badge-danger[href]:hover, .errorForm[href]:hover, .error-form[href]:hover, .badge-danger[href]:focus, .errorForm[href]:focus, .error-form[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert, .correct, .errorMessage, .confirmation_subscription .confirmation_validation {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #005c68;
  background-color: #cceff4;
  border-color: #b8e9f0; }
  .alert-primary hr {
    border-top-color: #a3e2ec; }
  .alert-primary .alert-link {
    color: #002f35; }

.alert-secondary {
  color: #006859;
  background-color: #ccf4ee;
  border-color: #b8f0e8; }
  .alert-secondary hr {
    border-top-color: #a3ece1; }
  .alert-secondary .alert-link {
    color: #00352d; }

.alert-success, .correct, .confirmation_subscription .confirmation_validation {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr, .correct hr, .confirmation_subscription .confirmation_validation hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link, .correct .alert-link, .confirmation_subscription .confirmation_validation .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger, .errorMessage {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr, .errorMessage hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link, .errorMessage .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #00b0c8;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active, .list-group-item.pager_current_page {
    z-index: 2;
    color: #fff;
    background-color: #00b0c8;
    border-color: #00b0c8; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #005c68;
  background-color: #b8e9f0; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #005c68;
    background-color: #a3e2ec; }
  .list-group-item-primary.list-group-item-action.active, .list-group-item-primary.list-group-item-action.pager_current_page {
    color: #fff;
    background-color: #005c68;
    border-color: #005c68; }

.list-group-item-secondary {
  color: #006859;
  background-color: #b8f0e8; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #006859;
    background-color: #a3ece1; }
  .list-group-item-secondary.list-group-item-action.active, .list-group-item-secondary.list-group-item-action.pager_current_page {
    color: #fff;
    background-color: #006859;
    border-color: #006859; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active, .list-group-item-success.list-group-item-action.pager_current_page {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active, .list-group-item-info.list-group-item-action.pager_current_page {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active, .list-group-item-warning.list-group-item-action.pager_current_page {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active, .list-group-item-danger.list-group-item-action.pager_current_page {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active, .list-group-item-light.list-group-item-action.pager_current_page {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active, .list-group-item-dark.list-group-item-action.pager_current_page {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active, .carousel-item.pager_current_page,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right,
.carousel-item-right.pager_current_page {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right,
    .carousel-item-right.pager_current_page {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left,
.carousel-item-left.pager_current_page {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left,
    .carousel-item-left.pager_current_page {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active, .carousel-indicators .pager_current_page {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #00b0c8 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #008395 !important; }

.bg-secondary {
  background-color: #00c8ac !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #009580 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #00b0c8 !important; }

.border-secondary {
  border-color: #00c8ac !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #00b0c8 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #008395 !important; }

.text-secondary {
  color: #00c8ac !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #009580 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge, .errorForm, .error-form {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

/* Modules d'E-majine */
/*	--------------------------------------------------
	--------------------------------------------------
		Agencement des blocs structurants :
	container, header, content, sidebar, footer
--------------------------------------------------
--------------------------------------------------*/
body.page-home {
  overflow-x: hidden; }

.header .header--topbar {
  background-color: #00b0c8;
  padding: 5px 0; }
  .header .header--topbar button.navbar-toggler {
    background-color: #ffffff;
    margin: 4px 0 10px 10px;
    padding: 0;
    border: transparent;
    width: 60px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10000;
    position: relative; }
    @media (min-width: 992px) {
      .header .header--topbar button.navbar-toggler {
        display: none; } }
    .header .header--topbar button.navbar-toggler:hover, .header .header--topbar button.navbar-toggler:focus, .header .header--topbar button.navbar-toggler:active {
      outline: none;
      box-shadow: none; }
    .header .header--topbar button.navbar-toggler span {
      width: 40px;
      height: 3px;
      background-color: #00b0c8;
      margin: 7px 0;
      transition: margin .3s; }
    .header .header--topbar button.navbar-toggler::before, .header .header--topbar button.navbar-toggler::after {
      content: "";
      width: 40px;
      height: 3px;
      background-color: #00b0c8;
      display: block; }
    .header .header--topbar button.navbar-toggler.collapsed span {
      margin: 4px 0; }
  .header .header--topbar .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (min-width: 992px) {
      .header .header--topbar .container {
        flex-wrap: nowrap;
        justify-content: flex-start; } }

.header .header--nav-mobile .logo {
  padding: 0;
  margin: 30px 0 0 0;
  text-align: center;
  display: block; }

.footer .footer--boxes-bloc {
  display: flex;
  align-items: center;
  margin: 7px 0;
  flex-direction: column;
  justify-content: center; }
  @media (min-width: 992px) {
    .footer .footer--boxes-bloc {
      flex-direction: row; } }
  .footer .footer--boxes-bloc.blocThree-rs > p {
    margin: 0;
    font-weight: 600;
    color: #000; }
    @media (min-width: 992px) {
      .footer .footer--boxes-bloc.blocThree-rs > p {
        margin: 0 10px 0 0; } }
  .footer .footer--boxes-bloc.blocFour a {
    color: #383838;
    font-size: 0.9rem;
    font-weight: 300; }
  .footer .footer--boxes-bloc.blocFour p {
    margin: 0;
    color: #383838;
    font-weight: 300;
    font-size: 0.9rem; }

.page-catalog header .header--photofull {
  padding: 90px 0; }
  .page-catalog header .header--photofull .box-cat-search {
    border-radius: 50px !important; }

.page-catalog .content {
  overflow: hidden; }
  .page-catalog .content .catalog-deco-bloc {
    position: absolute;
    width: 1280px;
    height: 1272px;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -1;
    display: none; }
    @media (min-width: 1400px) {
      .page-catalog .content .catalog-deco-bloc {
        display: block; } }
    .page-catalog .content .catalog-deco-bloc .catalog-deco {
      -webkit-clip-path: url(#svgPath);
      clip-path: url(#svgPath);
      height: 1190px;
      z-index: -1;
      -webkit-transform: rotate(-19deg);
      -ms-transform: rotate(-19deg);
      transform: rotate(-9deg);
      position: absolute;
      top: -60px;
      left: -470px; }
  .page-catalog .content .catalog-decoTwo-bloc {
    position: absolute;
    top: 0;
    right: 0px;
    width: 1173px;
    height: 970px;
    overflow: hidden;
    z-index: -1;
    display: none; }
    @media (min-width: 1400px) {
      .page-catalog .content .catalog-decoTwo-bloc {
        display: block; } }
    .page-catalog .content .catalog-decoTwo-bloc .catalog-decoTwo {
      height: 1190px;
      -webkit-clip-path: url(#svgPath2);
      clip-path: url(#svgPath2);
      z-index: -1;
      position: absolute;
      top: -30px;
      right: -1150px; }
  .page-catalog .content > .container {
    background-color: #fff;
    border-radius: 40px;
    padding: 50px 35px 0px 35px;
    max-width: 1400px;
    position: relative;
    z-index: 1;
    -webkit-box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.31);
    box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.31);
    margin: 0 auto 40px; }

.catalogProductDetail .content {
  overflow: hidden;
  padding: 40px 0; }
  .catalogProductDetail .content > .container {
    background-color: #fff;
    border-radius: 40px;
    padding: 50px 15px 0px 15px;
    position: relative;
    z-index: 1;
    -webkit-box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.31);
    box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.31);
    margin: 0 auto 40px; }
    @media (min-width: 1400px) {
      .catalogProductDetail .content > .container {
        max-width: 1400px;
        padding: 50px 35px 0px 35px; } }
    .catalogProductDetail .content > .container::before {
      content: "";
      width: 235px;
      height: 863px;
      position: absolute;
      top: 50px;
      left: calc(100% + 70px);
      background-image: url(/images/ficheproduit/png/fiche-produit-deco01.png); }
    .catalogProductDetail .content > .container::after {
      content: "";
      width: 180px;
      height: 710px;
      position: absolute;
      bottom: 50px;
      right: calc(100% + 70px);
      background-image: url(/images/ficheproduit/png/fiche-produit-deco02.png); }

/** RESPONSIVE **/
/**
*
* Eléments réutilisables :
* boutons, icônes, popin (lightbox, notification…), slider (styles communs)…
*
**/
body {
  min-height: 100vh;
  /* 1 */
  display: flex;
  /* 2 */
  flex-direction: column;
  /* 3 */
  font-family: 'Sora', sans-serif; }

.main {
  flex-grow: 1;
  /* 4 */ }

/* on évite les débordements */
img {
  max-width: 100%; }

.map {
  max-width: 100%;
  overflow: hidden; }
  .map > div {
    max-width: 100%;
    overflow: hidden; }

/* title */
h1 {
  font-size: 3.1rem;
  color: #f14a86;
  font-weight: bold;
  margin-bottom: 30px; }

h2, .h2 {
  color: #162983; }

h3, .h3 {
  color: #ff6e79; }

/* breadcrumb */
.breadcrumb {
  background-color: transparent; }

.breadcrumb-item + .breadcrumb-item:before {
  content: none; }

.breadcrumb-item:before {
  content: "-" !important;
  padding: 0 0px 0 5px !important; }

.breadcrumb-item:first-child:before, .breadcrumb-item:last-child:before {
  content: none !important; }

.breadcrumb-item a {
  color: #212529; }
  .breadcrumb-item a:hover, .breadcrumb-item a:focus {
    color: #00b0c8;
    text-decoration: none; }

/* formulaire */
.form_contact .form-control {
  border-radius: 20px !important;
  border: 2px solid #162983 !important; }

.form_contact .form-check-label {
  margin-left: 20px; }

/* pagination */
.pagination {
  justify-content: center;
  margin-top: 20px; }

/* boutons */
.btn.btn-primary {
  background-color: transparent;
  border: transparent;
  border-radius: 0;
  color: #00b0c8;
  font-weight: 700;
  display: inline-block;
  padding: 10px 40px 10px 20px;
  position: relative; }
  .btn.btn-primary:not(.gradient)::before {
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00b0c8;
    transition: width .3s;
    z-index: -1; }
  .btn.btn-primary::after {
    content: "\e91b";
    font-family: icomoon;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%) rotate(-150deg); }
  .btn.btn-primary:hover, .btn.btn-primary:active, .btn.btn-primary:focus {
    background-color: transparent !important;
    outline: none;
    color: #fff;
    box-shadow: none !important;
    text-decoration: none; }
    .btn.btn-primary:hover::before, .btn.btn-primary:active::before, .btn.btn-primary:focus::before {
      width: 100%; }

.btn.btn-secondary {
  background-color: transparent;
  border: transparent;
  border-radius: 0;
  position: relative;
  color: #fff;
  text-align: left;
  font-weight: 600;
  line-height: 1;
  padding: 49px 43px 45px 37px; }
  .btn.btn-secondary:not(.gradient)::before {
    content: "\e918";
    font-family: icomoon;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 11rem;
    color: #00b0c8;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    transition: color .3s, transform .3s; }
  .btn.btn-secondary::after {
    content: "\e91b";
    font-family: icomoon;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%) rotate(88deg);
    font-weight: 800;
    font-size: 1.1rem; }
  .btn.btn-secondary:hover, .btn.btn-secondary:active, .btn.btn-secondary:focus {
    background-color: transparent !important;
    outline: none;
    color: #fff;
    box-shadow: none !important;
    text-decoration: none; }
    .btn.btn-secondary:hover::before, .btn.btn-secondary:active::before, .btn.btn-secondary:focus::before {
      transform: scale(1.1);
      color: #00c8ac; }
  .btn.btn-secondary.gradient {
    background-color: #ff6e79;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #ff6e79), to(#f14a86));
    background-image: -o-linear-gradient(left, #ff6e79 1%, #f14a86 100%);
    background-image: linear-gradient(90deg, #ff6e79 1%, #f14a86);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$fifth",endColorstr="$fourth",GradientType=1);
    clip-path: url(#svgPath-link);
    padding: 49px 51px 58px 37px;
    transition: background-color .3s, clip-path .3s; }
    .btn.btn-secondary.gradient:hover, .btn.btn-secondary.gradient:active, .btn.btn-secondary.gradient:focus {
      background: #f14a86 !important; }
  .btn.btn-secondary.white {
    color: #ff6e79; }
    .btn.btn-secondary.white::before {
      color: #fff; }
    .btn.btn-secondary.white::after {
      color: #ff6e79; }
  .btn.btn-secondary.sixth {
    color: #fff; }
    .btn.btn-secondary.sixth::before {
      color: #ff8879; }
    .btn.btn-secondary.sixth::after {
      color: #fff; }
    .btn.btn-secondary.sixth:hover {
      color: #ff8879; }
      .btn.btn-secondary.sixth:hover::before {
        color: #fff; }
      .btn.btn-secondary.sixth:hover::after {
        color: #ff8879; }

.btn-prev:before {
  content: '\e603';
  padding-right: 0.5rem;
  vertical-align: -2px; }

.btn-next:after {
  content: '\e602';
  padding-left: 0.5rem;
  vertical-align: -2px; }

.btn-back:before {
  content: '\e609';
  padding-right: 0.5rem;
  vertical-align: -2px; }

.btn-back {
  background-color: #162983;
  color: #fff;
  border-radius: 30px; }
  .btn-back:hover, .btn-back:active, .btn-back:focus {
    color: #fff;
    box-shadow: none; }

/* galerie d'images*/
.cover {
  overflow: hidden; }
  .cover img {
    object-fit: cover;
    font-family: "object-fit: cover;";
    height: 100%;
    width: 100%; }

.article-gallery .lightbox {
  overflow: hidden;
  max-height: 12.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .article-gallery .lightbox {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 768px) {
    .article-gallery .lightbox {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  @media (min-width: 992px) {
    .article-gallery .lightbox {
      flex: 0 0 25%;
      max-width: 25%; } }

/* pagination*/
/*===================================
=            Alert Oldie            =
===================================*/
.oldie .alert-ie {
  border-top: 10px solid #e44204; }
  .oldie .alert-ie > div {
    padding: 20px 70px;
    background: url(/images/alert-ie.png) left center no-repeat; }
  .oldie .alert-ie strong {
    color: #e44204; }

/*=====  End of Alert Oldie  ======*/
/*==================================
=            Skip links            =
==================================*/
.skip-nav {
  position: absolute;
  padding: 0;
  list-style: none;
  z-index: 9999; }
  .skip-nav a {
    position: absolute;
    left: -7000px;
    padding: 0.5em;
    background: #343a40;
    color: white;
    text-decoration: none; }
    .skip-nav a:focus {
      position: static; }

/*=====  End of Skip links  ======*/
/*==========================================
=            Cookies validation            =
==========================================*/
.alert-cookie {
  transition: none;
  overflow: hidden;
  text-align: center;
  background-color: #212529;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  animation: cookie-anim 1s ease-in;
  padding: 10px;
  margin: 0; }
  .alert-cookie.is-hidden {
    animation: none;
    max-height: 0;
    padding: 0;
    margin: 0;
    transition: all 0.5s ease-out; }
  .alert-cookie.fixed-bottom {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0; }

@keyframes cookie-anim {
  0% {
    max-height: 0;
    padding: 0;
    margin: 0; }
  100% {
    max-height: 70px;
    padding: 10px; } }

.alert-cookie--link, .alert-cookie--link:visited {
  display: inline-block;
  color: #fff; }
  .alert-cookie--link:hover, .alert-cookie--link:focus, .alert-cookie--link:visited:hover, .alert-cookie--link:visited:focus {
    color: #fff; }

.alert-cookie--btn {
  /* Retrouver les class .btn et .btn-primary directement dans le fichier /zephyr/cookie.js */ }

/*=====  End of Cookies validation  ======*/
/*	--------------------------------------------------
	--------------------------------------------------
		gestion des comptes, et ses widgets
	----------------------------------------------
	Fichier : account (/account/login/box_login.html)
--------------------------------------------------
--------------------------------------------------*/
.box-login--logout i {
  vertical-align: -2px; }

.box-login--manage i {
  vertical-align: -2px; }

.account-action--item {
  margin-bottom: 30px; }

.account-action--link {
  display: block;
  text-align: center;
  padding: 20px;
  border: 1px solid #ced4da; }

/** RESPONSIVE **/
/*	--------------------------------------------------
	--------------------------------------------------
		 Petites Annonces et ses widgets
--------------------------------------------------
--------------------------------------------------*/
/** RESPONSIVE **/
/*	--------------------------------------------------
	--------------------------------------------------
		Forum et ses widgets
--------------------------------------------------
--------------------------------------------------*/
/** RESPONSIVE **/
/*	--------------------------------------------------
	--------------------------------------------------
		Widgets :
	propriétés générales, texte, actu, rss,
	banniere, calendrier, raccourcis, météo, sondage,
	recommander le site, newsletter, publicité

--------------------------------------------------
--------------------------------------------------*/
/* commons */
.no-title .box--title {
  display: none; }

/* text (/boxes/text/box_text.html) */
/* shortcuts header (/boxes/shortcut/header_shortcut.html) */
/* shortcuts aside (/boxes/shortcut/tools_shortcut.html) */
/* shortcuts footer (/boxes/shortcut/footer_shortcut.html) */
/* last elements (/boxes/last_elements/last_elements.html) */
/* newsletter (/newsletters/box_newsletters.html) */
/* search (/search/box_search.html) */
/* box-social (/z-templates/social.tmpl.html) */
.box-social--list {
  margin-bottom: 0; }

.box-social--item {
  display: inline-block;
  vertical-align: middle; }

.box-social--link {
  color: #00b0c8;
  width: 30px;
  height: 30px;
  text-align: center;
  padding-top: 7px;
  display: block;
  text-decoration: none; }

.header--boxes-name {
  margin: 0;
  flex-grow: 1;
  width: 100%; }
  @media (min-width: 992px) {
    .header--boxes-name {
      width: initial; } }
  .header--boxes-name .box-coordinates--address {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 992px) {
      .header--boxes-name .box-coordinates--address {
        justify-content: flex-start; } }
    .header--boxes-name .box-coordinates--address p {
      margin: 0;
      color: #fff;
      font-size: 0.8rem; }
      @media (min-width: 992px) {
        .header--boxes-name .box-coordinates--address p {
          font-size: 1rem; } }
    .header--boxes-name .box-coordinates--address span {
      color: #fff;
      margin: 0 4px 2px 0;
      font-size: 1.2rem; }

.box-login {
  margin: 0 0 0 15px;
  display: none; }
  @media (min-width: 992px) {
    .box-login {
      display: block; } }
  .box-login a {
    color: #fff;
    padding: 0; }
    .box-login a span {
      font-size: 1.5rem;
      transition: transform .1s; }
    .box-login a:hover, .box-login a:active, .box-login a:focus {
      background-color: transparent;
      color: #fff; }
      .box-login a:hover span, .box-login a:active span, .box-login a:focus span {
        transform: scale(1.2); }

.box-cart {
  display: none; }
  @media (min-width: 992px) {
    .box-cart {
      display: block; } }
  .box-cart p {
    margin: 0; }
    .box-cart p a {
      display: flex;
      align-items: center;
      color: #fff; }
      .box-cart p a span {
        font-size: 1.5rem;
        color: #162983;
        transition: transform .1s; }
      .box-cart p a:hover, .box-cart p a:active, .box-cart p a:focus {
        text-decoration: none; }
        .box-cart p a:hover span, .box-cart p a:active span, .box-cart p a:focus span {
          transform: scale(1.2); }

.header--boxes-numtel {
  position: relative;
  width: 127px;
  height: 21px;
  margin: 0; }
  @media (min-width: 992px) {
    .header--boxes-numtel {
      margin: 0 0 0 30px; } }
  .header--boxes-numtel p {
    margin: 0; }
    .header--boxes-numtel p a {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transition: letter-spacing .3s;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: left;
      font-size: 0.8rem; }
      @media (min-width: 992px) {
        .header--boxes-numtel p a {
          font-size: 1rem;
          text-align: center; } }
      .header--boxes-numtel p a:hover {
        letter-spacing: 1px;
        text-decoration: none; }

footer .header--boxes-numtel {
  width: 50%;
  margin: 0 0 10px 0; }
  @media (min-width: 992px) {
    footer .header--boxes-numtel {
      margin: 0 12px 0 0;
      width: 133px; } }
  footer .header--boxes-numtel a {
    color: #000;
    font-weight: 600; }
    @media (min-width: 992px) {
      footer .header--boxes-numtel a {
        padding: 0 10px 0 0; }
        footer .header--boxes-numtel a::after {
          content: "";
          position: absolute;
          top: 4px;
          right: 0;
          height: 15px;
          width: 2px;
          background-color: #000; } }

.header--boxes-rs {
  margin: 0;
  width: 50%; }
  @media (min-width: 992px) {
    .header--boxes-rs {
      margin: 0 0 0 30px;
      width: initial; } }

footer .header--boxes-rs {
  margin: 0; }
  footer .header--boxes-rs ul {
    justify-content: center; }
    @media (min-width: 992px) {
      footer .header--boxes-rs ul {
        justify-content: flex-start; } }
    footer .header--boxes-rs ul li a {
      margin: 0 3px; }
      footer .header--boxes-rs ul li a span {
        font-size: 2rem; }
        footer .header--boxes-rs ul li a span::before {
          color: #000; }

ul.box-social--list {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  @media (min-width: 992px) {
    ul.box-social--list {
      justify-content: flex-start; } }
  ul.box-social--list li a {
    padding: 0;
    color: #fff;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform .1s; }
    ul.box-social--list li a:hover {
      text-decoration: none;
      transform: scale(1.2); }
    ul.box-social--list li a span::before {
      color: #fff; }

@media (min-width: 992px) {
  .header--boxes-contact {
    margin: 0 0 0 30px; } }

.header--boxes-contact ul {
  margin: 0; }
  .header--boxes-contact ul li {
    position: relative;
    width: 77px;
    height: 21px; }
    .header--boxes-contact ul li a {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transition: letter-spacing .3s;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: left;
      font-size: 0.8rem; }
      @media (min-width: 992px) {
        .header--boxes-contact ul li a {
          font-size: 1rem;
          text-align: center; } }
      .header--boxes-contact ul li a:hover {
        letter-spacing: 1px;
        text-decoration: none; }

.footer-boxes-logofnaim {
  margin: 10px 0; }
  @media (min-width: 992px) {
    .footer-boxes-logofnaim {
      margin: 0 0 0 20px; } }
  .footer-boxes-logofnaim p {
    margin: 0; }

.footer--boxes-adresse {
  margin: 0; }
  .footer--boxes-adresse p {
    margin: 0; }

.footer--boxes-button {
  margin: 0 0 0 20px; }
  .footer--boxes-button ul {
    margin: 0; }

.footer--boxes-link ul {
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 992px) {
    .footer--boxes-link ul {
      flex-direction: row; } }
  .footer--boxes-link ul li a {
    color: #000;
    font-weight: 600;
    position: relative; }
    @media (min-width: 992px) {
      .footer--boxes-link ul li a {
        padding: 0 15px 0 0;
        margin: 0 15px 0 0; }
        .footer--boxes-link ul li a::after {
          content: "";
          position: absolute;
          top: 4px;
          right: 0;
          height: 15px;
          width: 2px;
          background-color: #000; } }

/** RESPONSIVE **/
/*	--------------------------------------------------
	--------------------------------------------------
		Catalogue et ses widgets
--------------------------------------------------
--------------------------------------------------*/
/* box search (/catalog/search/box_search.html) */
@-webkit-keyframes hvr-icon-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0); }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0); } }

@keyframes hvr-icon-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0); }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0); } }

.box-cat-search {
  -webkit-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.31);
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.31);
  background-color: #fff;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 50px; }
  .box-cat-search .box-cat-search--content form {
    display: flex;
    flex-direction: column; }
    @media (min-width: 992px) {
      .box-cat-search .box-cat-search--content form {
        flex-direction: row; } }
    .box-cat-search .box-cat-search--content form .box-cat-search--wrapper {
      flex-grow: 1;
      position: relative; }
      .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc {
        position: relative;
        padding: 20px 15px; }
        @media (min-width: 992px) {
          .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc {
            padding: 30px 70px 30px 30px; } }
        .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse {
          padding: 20px 0 0 0; }
        .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content {
          position: relative;
          display: flex;
          flex-wrap: wrap; }
          @media (min-width: 1200px) {
            .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content {
              flex-wrap: nowrap; } }
          .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields > div, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content > div {
            flex-grow: 1;
            margin: 0 0 10px 0;
            width: 100%; }
            @media (min-width: 1200px) {
              .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields > div, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content > div {
                margin: 0 6px;
                width: initial; } }
            .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields > div.form-group_check, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content > div.form-group_check {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 !important; }
              .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields > div.form-group_check label, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content > div.form-group_check label {
                color: #162983; }
            .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields > div.minWidth, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content > div.minWidth {
              min-width: 172px; }
          .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .dropdown, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .dropdown {
            position: relative; }
            .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .dropdown::before, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .dropdown::before {
              content: "\e608";
              font-family: icomoon;
              position: absolute;
              top: 12px;
              right: 14px;
              z-index: 1;
              -webkit-transform: rotate(90deg);
              -ms-transform: rotate(90deg);
              transform: rotate(90deg);
              font-size: 1.5rem;
              color: #162983;
              line-height: 1;
              font-weight: 800; }
            .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .dropdown button, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .dropdown button {
              border: 2px solid #162983;
              border-radius: 30px;
              color: #162983;
              position: relative;
              z-index: 2;
              background-color: transparent;
              padding: 10px 44px 10px 20px;
              font-size: 1rem; }
              .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .dropdown button:focus, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .dropdown button:focus {
                box-shadow: none; }
              .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .dropdown button::after, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .dropdown button::after {
                display: none !important; }
            .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .dropdown .dropdown-menu, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .dropdown .dropdown-menu {
              border: transparent;
              border-radius: 0;
              background-color: #fff;
              margin: 0; }
              .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .dropdown .dropdown-menu .custom-control.custom-checkbox > input, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .dropdown .dropdown-menu .custom-control.custom-checkbox > input {
                display: none; }
              .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .dropdown .dropdown-menu .custom-control.custom-checkbox label, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .dropdown .dropdown-menu .custom-control.custom-checkbox label {
                color: #162983;
                display: flex;
                align-items: center; }
                .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .dropdown .dropdown-menu .custom-control.custom-checkbox label span.checkbox-label, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .dropdown .dropdown-menu .custom-control.custom-checkbox label span.checkbox-label {
                  display: none; }
                .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .dropdown .dropdown-menu .custom-control.custom-checkbox label:before, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .dropdown .dropdown-menu .custom-control.custom-checkbox label:before {
                  display: block;
                  width: 1rem;
                  height: 1rem;
                  content: "";
                  margin: 0 7px 0 0; }
                .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .dropdown .dropdown-menu .custom-control.custom-checkbox label:after, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .dropdown .dropdown-menu .custom-control.custom-checkbox label:after {
                  position: absolute; }
          .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .form-group_date, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .form-group_date {
            display: flex;
            flex-direction: column; }
            .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .form-group_date label, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .form-group_date label {
              margin: 0;
              color: #162983; }
            .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .form-group_date .datepicker-container input, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .form-group_date .datepicker-container input {
              border: transparent;
              border-radius: 0;
              color: #162983;
              font-size: 1rem;
              font-weight: 600;
              position: relative;
              z-index: 2;
              background-color: transparent;
              padding: 0; }
              .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .form-group_date .datepicker-container input:-ms-input-placeholder, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .form-group_date .datepicker-container input:-ms-input-placeholder {
                color: #162983; }
              .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .form-group_date .datepicker-container input::-webkit-input-placeholder, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .form-group_date .datepicker-container input::-webkit-input-placeholder {
                color: #162983; }
              .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .form-group_date .datepicker-container input::placeholder, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .form-group_date .datepicker-container input::placeholder {
                color: #162983; }
              .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .form-group_date .datepicker-container input:focus, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .form-group_date .datepicker-container input:focus {
                box-shadow: none; }
            .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--fields .form-group_date::after, .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .box-cat-search--wrapper-bloc .box-cat-search--collapse-content .form-group_date::after {
              color: #162983;
              bottom: 6px;
              font-size: 1.5rem;
              top: initial;
              transform: translateY(0); }
      .box-cat-search .box-cat-search--content form .box-cat-search--wrapper button.btn.box-cat-search--more {
        margin: 40px 40px 0 auto;
        font-weight: 700;
        position: relative;
        color: #162983;
        font-size: 1.1rem;
        padding: 10px 10px 0 0px;
        position: absolute;
        right: 0; }
        @media (min-width: 992px) {
          .box-cat-search .box-cat-search--content form .box-cat-search--wrapper button.btn.box-cat-search--more {
            color: #00b0c8;
            margin: 0 90px 0 auto; } }
        .box-cat-search .box-cat-search--content form .box-cat-search--wrapper button.btn.box-cat-search--more::before {
          content: "+";
          position: absolute;
          top: -24px;
          right: -41px;
          background-color: #162983;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          color: #fff;
          font-size: 3.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          transition: transform .5s; }
          @media (min-width: 992px) {
            .box-cat-search .box-cat-search--content form .box-cat-search--wrapper button.btn.box-cat-search--more::before {
              background-color: #00b0c8; } }
        .box-cat-search .box-cat-search--content form .box-cat-search--wrapper button.btn.box-cat-search--more:hover::before {
          transform: rotate(180deg); }
      .box-cat-search .box-cat-search--content form .box-cat-search--wrapper input {
        border: 2px solid #162983;
        border-radius: 30px;
        color: #162983;
        position: relative;
        z-index: 2;
        background-color: transparent;
        padding: 10px 44px 10px 20px; }
        .box-cat-search .box-cat-search--content form .box-cat-search--wrapper input:-ms-input-placeholder {
          color: #162983; }
        .box-cat-search .box-cat-search--content form .box-cat-search--wrapper input::-webkit-input-placeholder {
          color: #162983; }
        .box-cat-search .box-cat-search--content form .box-cat-search--wrapper input::placeholder {
          color: #162983; }
        .box-cat-search .box-cat-search--content form .box-cat-search--wrapper input:focus {
          box-shadow: none; }
      .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .form-group {
        padding: 0;
        margin: 0; }
        .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .form-group::after {
          color: #162983; }
        .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .form-group.form-group_check label {
          color: #162983; }
        .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .form-group.button-footer {
          display: flex;
          justify-content: flex-end;
          position: absolute;
          width: 100%; }
          .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .form-group.button-footer .box-cat-search--more {
            position: relative !important;
            right: auto !important;
            margin: 0 90px 0 0; }
          .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .form-group.button-footer a {
            color: #162983;
            font-size: 1rem;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            font-weight: 700;
            padding: 10px 0 0 0;
            margin: 0 30px 0 0; }
            .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .form-group.button-footer a::before {
              content: "\e910";
              font-family: icomoon;
              margin: 0 9px 0 0; }
            .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .form-group.button-footer a:hover {
              text-decoration: none; }
              .box-cat-search .box-cat-search--content form .box-cat-search--wrapper .form-group.button-footer a:hover::before {
                -webkit-animation-name: hvr-icon-buzz-out;
                animation-name: hvr-icon-buzz-out;
                -webkit-animation-duration: 0.75s;
                animation-duration: 0.75s;
                -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
                -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1; }
    .box-cat-search .box-cat-search--content form .box-cat-search--submit-container {
      margin: 0; }
      .box-cat-search .box-cat-search--content form .box-cat-search--submit-container button, .box-cat-search .box-cat-search--content form .box-cat-search--submit-container button:active {
        width: 100%;
        border: transparent !important;
        background-color: #00c8ac !important;
        color: #fff;
        padding: 0; }
        @media (min-width: 992px) {
          .box-cat-search .box-cat-search--content form .box-cat-search--submit-container button, .box-cat-search .box-cat-search--content form .box-cat-search--submit-container button:active {
            height: 100%; } }
        .box-cat-search .box-cat-search--content form .box-cat-search--submit-container button span, .box-cat-search .box-cat-search--content form .box-cat-search--submit-container button:active span {
          font-size: 4rem; }

.ui-datepicker {
  background-color: #fff;
  padding: 10px;
  width: 270px;
  max-width: 100%;
  display: none; }
  .ui-datepicker.active, .ui-datepicker.pager_current_page {
    display: block; }
  .ui-datepicker .ui-datepicker-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .ui-datepicker .ui-datepicker-header a {
      font-size: 13px;
      background-color: #00b0c8;
      padding: 2px 10px;
      border-radius: 4px;
      cursor: pointer;
      color: #fff; }
      .ui-datepicker .ui-datepicker-header a span {
        display: none; }
      .ui-datepicker .ui-datepicker-header a:hover, .ui-datepicker .ui-datepicker-header a:focus, .ui-datepicker .ui-datepicker-header a:active {
        color: #fff !important; }
      .ui-datepicker .ui-datepicker-header a.ui-datepicker-next::after {
        content: "\e608";
        font-family: 'icomoon';
        font-size: 1.1rem; }
      .ui-datepicker .ui-datepicker-header a.ui-datepicker-prev::before {
        content: "\e607";
        font-family: 'icomoon';
        font-size: 1.1rem; }
    .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
      width: 100%;
      text-align: center;
      font-size: 18px;
      margin: 7px 0; }
  .ui-datepicker table.ui-datepicker-calendar {
    width: 100%; }
    .ui-datepicker table.ui-datepicker-calendar th {
      text-align: center; }
    .ui-datepicker table.ui-datepicker-calendar td {
      text-align: center;
      padding: 5px; }
      .ui-datepicker table.ui-datepicker-calendar td a {
        background-color: #00b0c8;
        color: #fff;
        margin: 1px;
        display: inline-block;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color .3s; }
        .ui-datepicker table.ui-datepicker-calendar td a:hover, .ui-datepicker table.ui-datepicker-calendar td a:active, .ui-datepicker table.ui-datepicker-calendar td a:focus {
          text-decoration: none;
          background-color: #c1c1c1; }
  .ui-datepicker .prod-dispo--legend p {
    text-align: center;
    margin: 5px 0 0 0;
    font-size: 13px; }
    .ui-datepicker .prod-dispo--legend p.legend-item.legend-item-enabled span.legend-square {
      width: 10px;
      height: 10px;
      background-color: #00b0c8;
      display: inline-block;
      margin: 0 5px 0 0; }

.box-cat-search--nav {
  display: flex;
  border: transparent;
  border-top-left-radius: 25px;
  overflow: hidden;
  border-top-right-radius: 25px;
  background-color: #fff;
  flex-direction: column; }
  @media (min-width: 1200px) {
    .box-cat-search--nav {
      flex-direction: row; }
      .box-cat-search--nav > a:first-child.active, .box-cat-search--nav > a.pager_current_page:first-child {
        border-top-right-radius: 25px !important;
        border-bottom-right-radius: 25px !important; }
      .box-cat-search--nav > a:nth-child(2).active, .box-cat-search--nav > a.pager_current_page:nth-child(2),
      .box-cat-search--nav > a:nth-child(3).active,
      .box-cat-search--nav > a.pager_current_page:nth-child(3) {
        border-radius: 25px !important; } }

.box-cat-search--nav--link {
  text-align: center;
  border: transparent;
  background: transparent;
  color: #d3d4d6;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 !important;
  padding: 15px 40px;
  border: transparent !important;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  transition: color .5s; }
  .box-cat-search--nav--link:hover, .box-cat-search--nav--link:focus {
    color: #162983; }
  .box-cat-search--nav--link.active, .box-cat-search--nav--link.pager_current_page {
    color: #fff !important;
    display: flex;
    font-weight: 700;
    background-color: #162983 !important; }
  @media (min-width: 1200px) {
    .box-cat-search--nav--link {
      width: initial;
      font-size: 1.1rem;
      padding: 7px 40px; } }
  @media (min-width: 1500px) {
    .box-cat-search--nav--link {
      font-size: 1.4rem; } }

.btn-select {
  text-align: left;
  padding-right: 28px;
  position: relative;
  color: red; }
  .btn-select:after {
    content: '\e91b';
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 10px;
    border: 0;
    top: 50%;
    height: 13px;
    width: 9px;
    transform: translateY(-50%);
    font-size: 12px; }

.dropdown-select {
  padding: 15px;
  min-width: 100%;
  margin: 0.1rem 0 0; }
  .dropdown-select .custom-control-label {
    text-transform: lowercase;
    white-space: nowrap; }
    .dropdown-select .custom-control-label:first-letter {
      text-transform: uppercase; }

.box-cat-search--more {
  background: transparent;
  color: red;
  font-size: 14px;
  display: flex;
  align-items: center;
  box-shadow: none;
  padding: 0;
  margin-bottom: 15px;
  border: none;
  margin: 10px 0 0 0; }
  .box-cat-search--more:after {
    display: none; }
  .box-cat-search--more:focus {
    outline: none;
    border: none;
    box-shadow: none; }
  .box-cat-search--more:hover, .box-cat-search--more:focus {
    color: red; }

.box-cat-search--more-icon {
  color: red;
  font-size: 20px;
  padding-right: 5px;
  font-weight: bold; }

.box-cat-search--submit {
  width: 100%;
  height: 80px;
  border: transparent;
  padding: 0;
  cursor: pointer;
  background-color: #162983;
  color: #fff;
  z-index: 1;
  font-weight: 700;
  border-radius: 40px;
  transition: transform .3s; }
  @media (min-width: 1200px) {
    .box-cat-search--submit {
      position: absolute;
      right: -110px;
      border-radius: 0;
      bottom: 0;
      background-color: transparent;
      width: 171px;
      height: 194px; }
      .box-cat-search--submit::before {
        content: "\e918";
        font-family: icomoon;
        position: absolute;
        top: 22px;
        left: 0;
        font-size: 12rem;
        color: #162983;
        z-index: -1;
        font-weight: initial;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(-55deg); }
      .box-cat-search--submit::after {
        font-family: icomoon;
        content: "\e91c";
        font-size: 4rem;
        font-weight: initial;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 50px; } }
  .box-cat-search--submit:hover, .box-cat-search--submit:focus, .box-cat-search--submit:active {
    outline: none;
    transform: scale(1.05); }

.form-group_check {
  padding: 8px 0 0 !important; }

.form-group_check .checkbox-value {
  display: none; }

.form-group_surface {
  position: relative; }
  .form-group_surface:after {
    content: "m²";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
    font-size: 14px;
    color: #212529; }
  .form-group_surface .form-control {
    padding-right: 30px; }

.form-group_price {
  position: relative; }
  .form-group_price:after {
    content: "€";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
    font-size: 14px;
    color: #212529; }
  .form-group_price .form-control {
    padding-right: 20px; }

.form-group_date {
  position: relative; }
  .form-group_date:after {
    content: "\e902";
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    font-size: 18px;
    color: #212529;
    pointer-events: none; }
    @media (min-width: 992px) {
      .form-group_date:after {
        right: 24px; } }
  .form-group_date .form-control {
    padding-right: 32px; }

.bienresum {
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.31);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.31);
  border-radius: 90px;
  overflow: hidden;
  margin-top: 15px !important;
  margin-bottom: 15px !important; }
  .bienresum .template-homepage--loc-list-item-img {
    height: 240px;
    transition: height .3s; }
    .bienresum .template-homepage--loc-list-item-img div {
      height: 100%; }
  .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos {
    background-color: #fff; }
    .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-titlebloc {
      display: flex;
      margin: 0;
      justify-content: space-between; }
      .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-titlebloc .template-homepage--loc-list-item-infos-title {
        width: 75%; }
        .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-titlebloc .template-homepage--loc-list-item-infos-title p.ref {
          color: #707070;
          margin: 15px 15px 0 15px;
          font-weight: 600; }
        .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-titlebloc .template-homepage--loc-list-item-infos-title h3.product-resum--infos--title {
          color: #000;
          font-size: 1rem;
          margin: 5px 15px 0px 15px;
          font-weight: 600; }
      .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-titlebloc .template-homepage--loc-list-item-infos-list {
        position: relative;
        width: 25%;
        min-width: 25%; }
        .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-titlebloc .template-homepage--loc-list-item-infos-list ul {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 0;
          margin: 0;
          width: 100%; }
          .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-titlebloc .template-homepage--loc-list-item-infos-list ul li {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            line-height: 1.2;
            background-color: #00b0c8;
            color: #fff;
            padding: 10px 5px;
            font-size: 0.7rem; }
            .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-titlebloc .template-homepage--loc-list-item-infos-list ul li span {
              font-size: 1.2rem;
              font-weight: 600;
              line-height: 1; }
            .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-titlebloc .template-homepage--loc-list-item-infos-list ul li.coeur {
              background-color: #ff6e79; }
    .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-resumbloc {
      display: flex;
      justify-content: space-between; }
      .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-resumbloc p {
        height: 130px;
        overflow: hidden;
        line-height: 1.2;
        color: #000;
        font-size: 0.9rem;
        margin: 10px 0 10px 15px;
        position: relative;
        width: 75%; }
        .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-resumbloc p::after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%);
          background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%);
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); }
      .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-resumbloc .template-homepage--loc-list-item-infos-resumbloc-list {
        position: relative;
        width: 25%;
        min-width: 25%; }
        .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-resumbloc .template-homepage--loc-list-item-infos-resumbloc-list ul {
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 0 5px;
          justify-content: center;
          align-items: center;
          text-align: center;
          height: 100%; }
          .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-resumbloc .template-homepage--loc-list-item-infos-resumbloc-list ul li {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            color: #000;
            margin: 6px 0; }
            .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-resumbloc .template-homepage--loc-list-item-infos-resumbloc-list ul li span {
              font-size: 1.8rem; }
  .bienresum .template-homepage--loc-list-item-infosbloc > p {
    text-align: center;
    background-color: #00b0c8;
    margin: 0;
    color: #fff;
    padding: 15px;
    transition: padding .3s; }
  .bienresum:hover, .bienresum:focus, .bienresum:active {
    text-decoration: none; }
    .bienresum:hover .template-homepage--loc-list-item-img, .bienresum:focus .template-homepage--loc-list-item-img, .bienresum:active .template-homepage--loc-list-item-img {
      height: 200px; }
    .bienresum:hover .template-homepage--loc-list-item-infosbloc > p, .bienresum:focus .template-homepage--loc-list-item-infosbloc > p, .bienresum:active .template-homepage--loc-list-item-infosbloc > p {
      padding: 35px 15px; }

.cat .family--desc h1 {
  font-size: 2.9rem;
  line-height: 34px;
  color: #f14a86;
  position: relative;
  margin: 20px 0 40px 0;
  display: inline-block; }
  .cat .family--desc h1:before {
    content: "\e91e";
    font-family: icomoon;
    font-weight: 800;
    color: #ff8879;
    position: absolute;
    top: -20px;
    right: 10px; }
  .cat .family--desc h1:after {
    content: "\e916";
    font-family: icomoon;
    font-weight: 800;
    font-size: 2rem;
    position: absolute;
    bottom: -30px;
    right: -10px;
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg); }

.cat .family--desc p {
  color: #162983;
  position: relative; }
  .cat .family--desc p::after {
    content: "\e91b";
    font-family: icomoon;
    position: absolute;
    bottom: -60px;
    font-weight: 800;
    -webkit-transform: rotate(72deg);
    -ms-transform: rotate(72deg);
    transform: rotate(72deg);
    font-size: 2rem;
    left: 5px;
    color: #f14a86; }

.cat form.sort-form {
  margin: 90px 0 30px;
  display: flex;
  align-items: flex-end; }
  @media (min-width: 992px) {
    .cat form.sort-form {
      margin: 90px 0 0 0; } }
  .cat form.sort-form .form-group {
    margin: 0 10px 0 0; }
    .cat form.sort-form .form-group label {
      margin: 0 10px 0 0;
      color: #00b0c8;
      font-weight: 600; }
    .cat form.sort-form .form-group select {
      border-radius: 0;
      border: 1px solid #00b0c8;
      padding: 0 5px 2px 5px;
      color: #00b0c8;
      margin: 0 10px 0 0; }
      .cat form.sort-form .form-group select:focus, .cat form.sort-form .form-group select:active, .cat form.sort-form .form-group select:hover {
        outline: none !important;
        color: #00b0c8 !important;
        box-shadow: none !important; }
  .cat form.sort-form .form-radio {
    margin: 0 0px 6px 0; }
    .cat form.sort-form .form-radio input {
      display: none; }
      .cat form.sort-form .form-radio input:checked + label {
        opacity: 1; }
    .cat form.sort-form .form-radio label {
      opacity: .5;
      cursor: pointer;
      background-color: #00b0c8;
      width: 18px;
      height: 14px;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%); }
      .cat form.sort-form .form-radio label.sort-prod--label_down {
        transform: rotate(180deg); }

.cat .cat--item {
  list-style-type: none; }
  @media (min-width: 992px) {
    .cat .cat--item {
      padding: 0 25px; } }
  .cat .cat--item .bienresum {
    display: block;
    margin: 0 0 50px 0 !important; }
    .cat .cat--item .bienresum .template-homepage--loc-list-item-infosbloc .template-homepage--loc-list-item-infos .template-homepage--loc-list-item-infos-titlebloc .template-homepage--loc-list-item-infos-list ul li span {
      font-size: 1rem; }

.product_detail .prod--btn-back {
  position: absolute;
  top: -20px;
  right: 40px; }

.product_detail .prod_vac--img .prod_vac--img-mainCarrousel .slick-arrow::before {
  color: #00b0c8;
  font-weight: 800;
  opacity: 1;
  font-size: 1.5rem; }

.product_detail .prod_vac--img .prod_vac--img-mainCarrousel .slick-arrow.slick-prev {
  left: 15px;
  z-index: 1; }

.product_detail .prod_vac--img .prod_vac--img-mainCarrousel .slick-arrow.slick-next {
  right: 15px; }

.product_detail .prod_vac--img .prod_vac--img-mainCarrousel .item {
  height: 200px;
  margin: 0 0 20px 0;
  border-radius: 80px;
  overflow: hidden; }
  @media (min-width: 992px) {
    .product_detail .prod_vac--img .prod_vac--img-mainCarrousel .item {
      height: 560px; } }
  .product_detail .prod_vac--img .prod_vac--img-mainCarrousel .item img {
    -o-object-fit: cover;
    object-fit: cover;
    font-family: "object-fit: cover;";
    height: 100%;
    width: 100%; }

.product_detail .prod_vac--img .prod_vac--img-secondCarrousel {
  margin: -10px 0; }
  .product_detail .prod_vac--img .prod_vac--img-secondCarrousel .item {
    height: 90px;
    max-width: 100%;
    border: transparent;
    border-radius: 50px;
    overflow: hidden; }
    @media (min-width: 992px) {
      .product_detail .prod_vac--img .prod_vac--img-secondCarrousel .item {
        height: 90px;
        height: 173px;
        margin: 10px 0;
        width: 300px !important; } }
    .product_detail .prod_vac--img .prod_vac--img-secondCarrousel .item img {
      -o-object-fit: cover;
      object-fit: cover;
      font-family: "object-fit: cover;";
      height: 100%;
      width: 100%;
      opacity: .5;
      transition: opacity .8s; }
    .product_detail .prod_vac--img .prod_vac--img-secondCarrousel .item:hover img, .product_detail .prod_vac--img .prod_vac--img-secondCarrousel .item:focus img, .product_detail .prod_vac--img .prod_vac--img-secondCarrousel .item:active img {
      opacity: 1; }

.product_detail .product_detail--infos {
  padding: 30px 0 0 0; }
  .product_detail .product_detail--infos p.product_detail--infos-ref {
    margin: 0;
    color: #adadad;
    font-size: .9rem; }
  .product_detail .product_detail--infos .product_detail--infos-elementtitle {
    display: flex;
    flex-direction: column; }
    @media (min-width: 992px) {
      .product_detail .product_detail--infos .product_detail--infos-elementtitle {
        flex-direction: row; } }
    .product_detail .product_detail--infos .product_detail--infos-elementtitle .product_detail--infos--title {
      line-height: 1;
      margin: 10px 10px 10px 0;
      font-weight: 700;
      color: #f14a86;
      font-size: 1.7rem; }
      @media (min-width: 992px) {
        .product_detail .product_detail--infos .product_detail--infos-elementtitle .product_detail--infos--title {
          font-size: 3rem; } }
    .product_detail .product_detail--infos .product_detail--infos-elementtitle .product_detail--infos-elementtitle-tools {
      display: flex; }
      @media (min-width: 992px) {
        .product_detail .product_detail--infos .product_detail--infos-elementtitle .product_detail--infos-elementtitle-tools {
          padding: 20px 0 0 10px; } }
      .product_detail .product_detail--infos .product_detail--infos-elementtitle .product_detail--infos-elementtitle-tools span.icon-printer1 {
        font-size: 2.5rem;
        color: #00b0c8; }
      .product_detail .product_detail--infos .product_detail--infos-elementtitle .product_detail--infos-elementtitle-tools .box-sharing_prod {
        margin-right: 8px; }
        @media (min-width: 992px) {
          .product_detail .product_detail--infos .product_detail--infos-elementtitle .product_detail--infos-elementtitle-tools .box-sharing_prod {
            margin-left: auto; } }
        .product_detail .product_detail--infos .product_detail--infos-elementtitle .product_detail--infos-elementtitle-tools .box-sharing_prod .box-sharing--btn {
          color: #00b0c8;
          background: #fff;
          border-radius: 50%;
          width: 35px;
          height: 35px;
          display: flex;
          padding: 0;
          justify-content: center;
          align-items: center;
          font-size: 35px; }
          .product_detail .product_detail--infos .product_detail--infos-elementtitle .product_detail--infos-elementtitle-tools .box-sharing_prod .box-sharing--btn:hover, .product_detail .product_detail--infos .product_detail--infos-elementtitle .product_detail--infos-elementtitle-tools .box-sharing_prod .box-sharing--btn:focus {
            color: #00b0c8;
            background: #fff; }
          .product_detail .product_detail--infos .product_detail--infos-elementtitle .product_detail--infos-elementtitle-tools .box-sharing_prod .box-sharing--btn:after {
            display: none; }
        .product_detail .product_detail--infos .product_detail--infos-elementtitle .product_detail--infos-elementtitle-tools .box-sharing_prod .box-sharing--link i {
          padding-right: 8px;
          width: 24px;
          text-align: center; }
  .product_detail .product_detail--infos .product_detail--infos-caracteristiques {
    display: flex;
    align-items: center;
    padding: 10px 0 20px 0; }
    .product_detail .product_detail--infos .product_detail--infos-caracteristiques p {
      color: #000;
      margin: 0 20px 0 0; }
      @media (min-width: 992px) {
        .product_detail .product_detail--infos .product_detail--infos-caracteristiques p {
          margin: 0 40px 0 0; } }
      .product_detail .product_detail--infos .product_detail--infos-caracteristiques p span {
        font-size: 1.7rem;
        margin: 0 4px 0 0; }
        @media (min-width: 992px) {
          .product_detail .product_detail--infos .product_detail--infos-caracteristiques p span {
            margin: 0 14px 0 0; } }
      .product_detail .product_detail--infos .product_detail--infos-caracteristiques p img {
        margin: 0 15px 0 0; }
  .product_detail .product_detail--infos .product_detail--info-descBloc {
    border-bottom: 1px solid #d8dbea;
    padding: 0 0 30px;
    margin: 0 0 30px; }
    .product_detail .product_detail--infos .product_detail--info-descBloc a {
      text-decoration: underline;
      color: #f14a86; }
  .product_detail .product_detail--infos .product_detail--info-desc {
    padding: 0 0 30px 0;
    font-weight: 600;
    overflow: hidden;
    opacity: 0;
    transition: height .5s;
    margin: 0 0 30px 0; }
    .product_detail .product_detail--infos .product_detail--info-desc .product_detail--info-desc-more {
      overflow: hidden;
      -webkit-transition: height .5s;
      -o-transition: height .5s;
      transition: height .5s; }
      .product_detail .product_detail--infos .product_detail--info-desc .product_detail--info-desc-more ul {
        padding: 30px 0 0 0;
        margin: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; }
        .product_detail .product_detail--infos .product_detail--info-desc .product_detail--info-desc-more ul li {
          width: 100%;
          list-style-type: none;
          font-weight: 600;
          margin: 0 0 4px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center; }
          @media (min-width: 992px) {
            .product_detail .product_detail--infos .product_detail--info-desc .product_detail--info-desc-more ul li {
              width: 49%; } }
          .product_detail .product_detail--infos .product_detail--info-desc .product_detail--info-desc-more ul li::before {
            content: "";
            width: 20px;
            height: 4px;
            background-color: #00b0c8;
            margin: 0 7px 0 0;
            border-radius: 3px; }
  .product_detail .product_detail--infos .product_detail--info-equipementsList {
    border-bottom: 1px solid #d8dbea;
    padding: 0 0 30px 0;
    margin: 0 0 30px 0; }
    .product_detail .product_detail--infos .product_detail--info-equipementsList h2 {
      color: #000;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin: 0 0 30px 0;
      font-size: 1.5rem; }
      @media (min-width: 992px) {
        .product_detail .product_detail--infos .product_detail--info-equipementsList h2 {
          font-size: 2rem; } }
      .product_detail .product_detail--infos .product_detail--info-equipementsList h2::before {
        content: "+";
        background-color: #00b0c8;
        color: #fff;
        width: 30px;
        min-width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 2rem;
        margin: 0 10px 0 0; }
    .product_detail .product_detail--infos .product_detail--info-equipementsList a {
      text-decoration: underline;
      display: inline-block;
      margin: 20px 0 0 0;
      color: #f14a86; }
    .product_detail .product_detail--infos .product_detail--info-equipementsList.lesPLus {
      border-bottom: transparent; }
      .product_detail .product_detail--infos .product_detail--info-equipementsList.lesPLus .product_detail--info-equipementsList-elems {
        opacity: 1; }
    .product_detail .product_detail--infos .product_detail--info-equipementsList .product_detail--info-equipementsList-elems {
      opacity: 0;
      overflow: hidden;
      transition: height .5s; }
      .product_detail .product_detail--infos .product_detail--info-equipementsList .product_detail--info-equipementsList-elems ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }
        .product_detail .product_detail--infos .product_detail--info-equipementsList .product_detail--info-equipementsList-elems ul li {
          width: 100%;
          list-style-type: none;
          font-weight: 600;
          margin: 0 0 4px 0;
          display: flex;
          align-items: center; }
          @media (min-width: 992px) {
            .product_detail .product_detail--infos .product_detail--info-equipementsList .product_detail--info-equipementsList-elems ul li {
              width: 49%; } }
          .product_detail .product_detail--infos .product_detail--info-equipementsList .product_detail--info-equipementsList-elems ul li::before {
            content: "";
            width: 20px;
            height: 4px;
            background-color: #00b0c8;
            margin: 0 7px 0 0;
            border-radius: 3px; }
  .product_detail .product_detail--infos .product_detail-sidebarBloc {
    padding: 50px 35px;
    margin: 0 0 30px;
    border-radius: 60px; }
    .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo--alert-warning {
      background-color: #fff;
      padding: 30px; }
    .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo--alert-sucess {
      background-color: #fff;
      padding: 30px; }
    .product_detail .product_detail--infos .product_detail-sidebarBloc.product_detail-dispotarifs {
      background: #00b0c8;
      background: -webkit-gradient(linear, left top, left bottom, from(#00b0c8), to(#00c8ac));
      background: -o-linear-gradient(top, #00b0c8 0, #00c8ac 100%);
      background: linear-gradient(180deg, #00b0c8 0, #00c8ac);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$primary",endColorstr="$secondary",GradientType=0); }
    .product_detail .product_detail--infos .product_detail-sidebarBloc.product_detail-interested {
      background-color: #ff8879; }
      .product_detail .product_detail--infos .product_detail-sidebarBloc.product_detail-interested button {
        border: transparent;
        margin: 20px 0 0;
        background-color: #162983;
        text-align: left;
        padding: 10px 30px !important;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
        -webkit-transition: background-color .3s;
        -o-transition: background-color .3s;
        transition: background-color .3s;
        color: #fff;
        border-radius: 50px;
        font-size: 1.3rem; }
        .product_detail .product_detail--infos .product_detail-sidebarBloc.product_detail-interested button span {
          font-size: 1.6rem;
          margin: 0 0 0 20px;
          transition: transform .5s; }
        .product_detail .product_detail--infos .product_detail-sidebarBloc.product_detail-interested button:hover, .product_detail .product_detail--infos .product_detail-sidebarBloc.product_detail-interested button:focus, .product_detail .product_detail--infos .product_detail-sidebarBloc.product_detail-interested button:active {
          outline: none; }
          .product_detail .product_detail--infos .product_detail-sidebarBloc.product_detail-interested button:hover span, .product_detail .product_detail--infos .product_detail-sidebarBloc.product_detail-interested button:focus span, .product_detail .product_detail--infos .product_detail-sidebarBloc.product_detail-interested button:active span {
            transform: scale(1.3); }
    .product_detail .product_detail--infos .product_detail-sidebarBloc h2 {
      color: #fff;
      text-transform: uppercase;
      line-height: 1;
      margin: 0 0 10px 0; }
    .product_detail .product_detail--infos .product_detail-sidebarBloc p {
      color: #fff;
      margin: 0; }
      .product_detail .product_detail--infos .product_detail-sidebarBloc p.alert-success.alert, .product_detail .product_detail--infos .product_detail-sidebarBloc p.correct, .product_detail .product_detail--infos .product_detail-sidebarBloc p.alert-success.errorMessage, .product_detail .product_detail--infos .product_detail-sidebarBloc p.errorMessage.correct, .product_detail .product_detail--infos .product_detail-sidebarBloc .confirmation_subscription p.confirmation_validation, .confirmation_subscription .product_detail .product_detail--infos .product_detail-sidebarBloc p.confirmation_validation {
        color: #155724;
        margin: 0 0 20px 0; }
      .product_detail .product_detail--infos .product_detail-sidebarBloc p.alert-warning {
        color: #856404;
        margin: 0 0 20px 0; }
    .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo p.prod-dispo--price {
      background-color: #fff;
      color: #00b0c8 !important;
      font-weight: 600;
      font-size: 1.5rem;
      display: inline-block;
      padding: 4px 15px;
      line-height: 1;
      margin: 20px 0 0 0 !important; }
    .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 20px 0 0 0; }
      .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--btn-container a, .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--btn-container button {
        background-color: #000 !important;
        color: #fff;
        text-align: center;
        padding: 20px 0;
        text-transform: uppercase;
        border: transparent; }
        .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--btn-container a:hover, .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--btn-container a:focus, .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--btn-container a:active, .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--btn-container button:hover, .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--btn-container button:focus, .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--btn-container button:active {
          background-color: #fff !important;
          color: #000 !important; }
        .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--btn-container a::after, .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--btn-container a::before, .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--btn-container button::after, .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--btn-container button::before {
          display: none; }
      .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form a {
        background-color: #fff !important;
        width: 100%;
        border: transparent;
        font-size: 1.2rem;
        padding: 5px 20px;
        margin: 5px 0;
        text-align: left; }
        .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form a:after {
          display: none; }
      .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .datepicker-container {
        position: relative; }
        .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .datepicker-container::after {
          content: "\e902";
          font-family: icomoon;
          color: #fff;
          position: absolute;
          top: 8px;
          right: 20px;
          font-size: 2rem; }
        .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .datepicker-container input {
          width: 100%;
          border: 1px solid #fff;
          background-color: transparent;
          color: #fff;
          margin: 0 0 10px 0;
          padding: 15px 60px 15px 20px;
          font-size: 1.2rem;
          border-radius: 50px; }
          .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .datepicker-container input:-ms-input-placeholder {
            color: #fff; }
          .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .datepicker-container input::-webkit-input-placeholder {
            color: #fff; }
          .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .datepicker-container input::placeholder {
            color: #fff; }
          .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .datepicker-container input:hover, .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .datepicker-container input:focus, .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .datepicker-container input:active {
            outline: none; }
      .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--valide-date-container button {
        background-color: #162983;
        width: 100%;
        font-size: 1.3rem;
        text-align: center;
        padding: 5px 20px;
        color: #fff;
        border-radius: 50px; }
        .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--valide-date-container button span {
          font-size: 3rem;
          transition: transform .5s; }
        .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--valide-date-container button:hover, .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--valide-date-container button:focus, .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--valide-date-container button:active {
          background-color: #162983 !important;
          color: #fff !important; }
          .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--valide-date-container button:hover span, .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--valide-date-container button:focus span, .product_detail .product_detail--infos .product_detail-sidebarBloc .prod-dispo form .prod-dispo--valide-date-container button:active span {
            transform: scale(1.3); }

.product_detail-map {
  padding: 40px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .product_detail-map h2 {
    text-align: center;
    margin: 0 0 40px 0;
    color: #f14a86;
    position: relative;
    display: inline-block; }
    .product_detail-map h2::before {
      content: "\e916";
      font-family: icomoon;
      font-weight: 800;
      font-size: 1.7rem;
      position: absolute;
      bottom: -20px;
      left: -40px;
      -webkit-transform: rotate(-15deg);
      -ms-transform: rotate(-15deg);
      transform: rotate(-15deg); }

.product_detail--info-descBloc_2 {
  border-bottom: none !important; }

.product_detail-dispotarifs-title {
  margin-bottom: 20px !important; }

.product_detail-dispotarifs-img {
  max-width: 220px;
  color: #fff;
  margin-top: 10px;
  text-align: center; }

.infodpeges {
  text-transform: lowercase;
  position: relative;
  z-index: 1;
  font-size: 20px;
  cursor: pointer;
  margin-left: 15px;
  display: inline-block;
  vertical-align: middle; }
  .infodpeges:before {
    content: '';
    width: 24px;
    height: 24px;
    background-color: #162983;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1; }

.cart header.cart-header {
  background-color: #efefef;
  padding: 6px 15px;
  margin: 20px 0 30px 0; }
  .cart header.cart-header ol {
    margin: 0; }
    .cart header.cart-header ol li {
      border-right: 1px solid #00b0c8;
      text-align: center; }
      .cart header.cart-header ol li.active, .cart header.cart-header ol li.pager_current_page {
        color: #00b0c8;
        font-weight: 800; }
      .cart header.cart-header ol li:last-child {
        border-right: transparent; }

.cart .cart-submit .btn.btn-arrow {
  padding: 3px 50px 5px 25px !important; }

#cartAuthentification .cart--title {
  text-align: center; }

#cartAuthentification .formulaire-tunnel > h2 {
  text-align: center; }

#cartAuthentification input.btn-primary {
  background-color: #00b0c8;
  color: #fff;
  padding: 10px 20px; }
  #cartAuthentification input.btn-primary:hover, #cartAuthentification input.btn-primary:active, #cartAuthentification input.btn-primary:focus {
    background-color: #00c8ac !important;
    color: #fff; }

#cart_order_confirmation .bg-primary {
  padding: 10px 20px; }
  #cart_order_confirmation .bg-primary * {
    color: #fff; }
  #cart_order_confirmation .bg-primary .btn-primary {
    border: 2px solid #fff; }
    #cart_order_confirmation .bg-primary .btn-primary:hover, #cart_order_confirmation .bg-primary .btn-primary:active, #cart_order_confirmation .bg-primary .btn-primary:focus {
      border: 2px solid #fff; }

div#payment_infos > .row {
  margin: 0; }

#cart_payment_confirmation .col-12.col-lg-4.bordered > .bg-primary {
  padding: 20px; }
  #cart_payment_confirmation .col-12.col-lg-4.bordered > .bg-primary * {
    color: #fff; }

/* responsive*/
/*	--------------------------------------------------
	--------------------------------------------------
		Communauté et ses widgets :
		notations, commentaires, tags
--------------------------------------------------
--------------------------------------------------*/
/* -------------------------------------------- */
/* ETOILES DES NOTATIONS                        */
/* -------------------------------------------- */
.ui-stars-cancel, .ui-stars-star {
  display: inline-block;
  width: 17px;
  height: 15px;
  text-indent: -999em;
  cursor: pointer;
  background: transparent;
  overflow: hidden; }

.ui-stars-cancel, .ui-stars-cancel a {
  background: url(/common_images/skin001/star-delete.gif) no-repeat 0 -16px; }

.ui-stars-star, .ui-stars-star a {
  background: url(/common_images/skin001/star.gif) no-repeat 0 0; }

.ui-stars-cancel a, .ui-stars-star a {
  display: block;
  width: 16px;
  height: 100%;
  background-position: 0 0; }

.ui-stars-star-on a {
  background-position: 0 -16px !important; }

.ui-stars-star-hover a {
  background-position: 0 -32px; }

.ui-stars-cancel-hover a {
  background-position: 0 -16px; }

.ui-stars-cancel-disabled a, .ui-stars-star-disabled,
.ui-stars-star-disabled a {
  cursor: default !important; }

.ui-stars-star {
  background: transparent !important;
  overflow: hidden !important; }

/** RESPONSIVE **/
/*	--------------------------------------------------
	--------------------------------------------------
		Annuaire et ses widgets
--------------------------------------------------
--------------------------------------------------*/
/* box entries (/directory/boxes/entries.html) */
/* responsive*/
/*	--------------------------------------------------
	--------------------------------------------------
		Module E-commerce
--------------------------------------------------
--------------------------------------------------*/
/* commons */
/* cart (/catalog/cart/cart.html) */
/* box cart (/catalog/cart/box_cart.html) */
/** RESPONSIVE **/
/**
*
* Navigation
* Menu, breadcrumb, pagination, pager, ...
*
**/
/* gestion du tactile quand le menu repasse en desktop */
@media (min-width: 768px) {
  .navbar-nav .nav-item:hover > .dropdown-menu, .navbar-nav .nav-item:focus > .dropdown-menu {
    display: block; }
  .no-touch .navbar-nav li:hover > ul.dropdown-menu {
    display: none; }
  .no-touch .navbar-nav li.open > ul.dropdown-menu {
    display: block; }
  .navbar-nav .dropdown-menu {
    margin-top: 0; }
  .dropdown-menu .dropdown-menu {
    top: 0;
    left: 100%; } }

/*=======================================
=            Animation Menu             =
=======================================*/
/*=====  End of Animation Menu ======*/
ul#menu-main {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background-color: #efefef;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 70px 30px 30px 10px; }
  @media (min-width: 992px) {
    ul#menu-main {
      padding: 0;
      height: initial;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      top: initial;
      left: initial;
      background-color: transparent; } }
  ul#menu-main > a {
    order: 3;
    margin: 0;
    padding: 0; }
  ul#menu-main > li {
    order: 4; }
    ul#menu-main > li:nth-child(2) {
      order: 1; }
    ul#menu-main > li:nth-child(3) {
      order: 2; }
    ul#menu-main > li > a {
      text-transform: uppercase;
      font-weight: 700;
      color: #00b0c8;
      font-size: 1.2rem;
      transition: color .3s; }
      @media (min-width: 992px) {
        ul#menu-main > li > a {
          font-size: 1rem; } }
    ul#menu-main > li .dropdown-menu {
      width: 100%;
      border: transparent;
      background-color: #efefef; }
      ul#menu-main > li .dropdown-menu a {
        text-transform: uppercase;
        font-size: 0.9rem;
        font-weight: 700;
        color: #162983 !important; }
    ul#menu-main > li:hover a {
      color: #162983; }

ol.breadcrumb {
  border-radius: 0;
  background-color: transparent; }
  ol.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
    content: "-";
    padding: 0 0 0 5px;
    color: #00b0c8; }

ul.pagination {
  margin: 0;
  background-color: #00b0c8;
  padding: 40px;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 0; }
  ul.pagination::before, ul.pagination::after {
    content: "";
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    background-color: #00b0c8;
    width: 1000px; }
  ul.pagination::after {
    right: initial;
    left: 100%; }
  ul.pagination li {
    display: flex;
    align-items: center;
    margin: 0 3px; }
    ul.pagination li a {
      padding: 0;
      background-color: transparent;
      color: #fff;
      border: transparent;
      border-radius: 0;
      line-height: 1; }
      ul.pagination li a:hover, ul.pagination li a:active, ul.pagination li a:focus {
        background-color: transparent;
        color: #fff; }
    ul.pagination li::after {
      content: ".";
      color: #fff;
      margin: 0 5px 0 12px; }
    ul.pagination li:last-child::after, ul.pagination li.pagerNext::after, ul.pagination li.pagerLast::after {
      display: none; }

/*	--------------------------------------------------
	--------------------------------------------------
		méthodes de publication:
	communs, actualités, articles, événements
--------------------------------------------------
--------------------------------------------------*/
/* media (bootstrap) : présentation famille, header news detail, header event detail, structure bloc&image emajine */
.media {
  flex-wrap: wrap; }

@media (min-width: 576px) {
  .media {
    flex-wrap: nowrap; }
  .media-img {
    max-width: 25%; } }

.template-homepage .container {
  position: relative; }
  .template-homepage .container .template-homepage--imgdecoOne, .template-homepage .container .template-homepage--imgdecoTwo {
    position: absolute;
    top: -340px;
    right: calc(100% - 450px);
    width: 1300px;
    z-index: -1;
    display: none; }
    @media (min-width: 1500px) {
      .template-homepage .container .template-homepage--imgdecoOne, .template-homepage .container .template-homepage--imgdecoTwo {
        display: block; } }
    .template-homepage .container .template-homepage--imgdecoOne .template-homepage--imgdecoMask, .template-homepage .container .template-homepage--imgdecoTwo .template-homepage--imgdecoMask {
      clip-path: url(#svgPath);
      position: relative;
      height: 1190px; }
  .template-homepage .container .template-homepage--imgdecoOne::before {
    content: "\e920";
    font-family: icomoon;
    position: absolute;
    z-index: 9999;
    right: calc(100% - 1120px);
    bottom: 50px;
    font-size: 70rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    color: #00c8ac;
    opacity: 0;
    transform: translateY(-50px);
    transition: opacity 1s, transform 1s;
    transition-delay: 1s; }
  .template-homepage .container .template-homepage--imgdecoOne.aos-animate::before {
    opacity: 1;
    transform: translateY(0); }
  .template-homepage .container .template-homepage--imgdecoTwo {
    left: calc(100% - 270px);
    right: auto;
    top: -100px; }
    .template-homepage .container .template-homepage--imgdecoTwo .template-homepage--imgdecoMask {
      clip-path: url(#svgPath2); }
    .template-homepage .container .template-homepage--imgdecoTwo::before {
      content: "";
      width: 961px;
      height: 838px;
      position: absolute;
      top: 210px;
      left: 280px;
      background: #00b0c8;
      background: -webkit-gradient(linear, left top, left bottom, from(#00b0c8), to(#00c8ac));
      background: -o-linear-gradient(top, #00b0c8 0, #00c8ac 100%);
      background: linear-gradient(180deg, #00b0c8 0, #00c8ac);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$primary",endColorstr="$secondary",GradientType=0);
      -webkit-clip-path: url(#svgPath2-before);
      clip-path: url(#svgPath2-before);
      opacity: 0;
      transform: translateY(50px);
      transition: opacity 1s, transform 1s;
      transition-delay: 1.5s; }
    .template-homepage .container .template-homepage--imgdecoTwo.aos-animate::before {
      opacity: 1;
      transform: translateY(0); }

.template-homepage .template-homepage--searchform {
  margin: 10px 0 140px 0; }
  @media (min-width: 992px) {
    .template-homepage .template-homepage--searchform {
      padding: 0 125px;
      margin: 80px 0 90px 0; } }
  @media (min-width: 1500px) {
    .template-homepage .template-homepage--searchform {
      padding: 0 55px;
      margin: 80px 0 140px 0; } }

.template-homepage .template-homepage--principaltitle {
  display: flex;
  justify-content: center; }
  @media (min-width: 1200px) {
    .template-homepage .template-homepage--principaltitle {
      justify-content: flex-start; } }
  @media (min-width: 1500px) {
    .template-homepage .template-homepage--principaltitle {
      justify-content: center; } }
  .template-homepage .template-homepage--principaltitle h2 {
    font-size: 2.9rem;
    line-height: 34px;
    color: #f14a86;
    position: relative;
    margin: 0;
    text-align: center; }
    @media (min-width: 992px) {
      .template-homepage .template-homepage--principaltitle h2 {
        text-align: left; } }
    @media (min-width: 1500px) {
      .template-homepage .template-homepage--principaltitle h2 {
        margin: 0 0 0 50px; } }
    .template-homepage .template-homepage--principaltitle h2 p {
      margin: 0;
      position: relative; }
      .template-homepage .template-homepage--principaltitle h2 p strong {
        font-weight: 800; }
      .template-homepage .template-homepage--principaltitle h2 p::before {
        content: "\e91b";
        font-family: icomoon;
        position: absolute;
        bottom: -50px;
        font-weight: 800;
        transform: rotate(72deg);
        font-size: 2rem;
        left: 25px; }
    .template-homepage .template-homepage--principaltitle h2::before {
      content: "\e91e";
      font-family: icomoon;
      font-weight: 800;
      color: #ff8879;
      position: absolute;
      top: -20px;
      right: 10px; }
    .template-homepage .template-homepage--principaltitle h2::after {
      content: "\e916";
      font-family: icomoon;
      font-weight: 800;
      font-size: 2rem;
      position: absolute;
      bottom: -30px;
      right: -10px;
      transform: rotate(-15deg); }

.template-homepage .template-homepage--loc {
  margin: 70px -15px 0 -15px; }
  @media (min-width: 992px) {
    .template-homepage .template-homepage--loc {
      margin: 90px -15px 0 -15px; } }
  @media (min-width: 1500px) {
    .template-homepage .template-homepage--loc {
      margin: 240px -15px 0 -15px; } }
  .template-homepage .template-homepage--loc .template-homepage--loc-list {
    padding: 0 0 50px 0; }
    @media (min-width: 992px) {
      .template-homepage .template-homepage--loc .template-homepage--loc-list {
        padding: 0; } }
    .template-homepage .template-homepage--loc .template-homepage--loc-list .bienresum {
      margin: 0 15px; }
    .template-homepage .template-homepage--loc .template-homepage--loc-list .slick-arrow {
      top: initial;
      bottom: 0;
      right: 20px;
      left: initial;
      opacity: 1;
      width: 22px;
      height: 52px;
      transform: translateY(0); }
      @media (min-width: 992px) {
        .template-homepage .template-homepage--loc .template-homepage--loc-list .slick-arrow {
          bottom: 80px;
          right: -25px; } }
      .template-homepage .template-homepage--loc .template-homepage--loc-list .slick-arrow.slick-prev {
        transform: rotate(180deg);
        right: 60px; }
        @media (min-width: 992px) {
          .template-homepage .template-homepage--loc .template-homepage--loc-list .slick-arrow.slick-prev {
            right: initial;
            left: -25px; } }
      .template-homepage .template-homepage--loc .template-homepage--loc-list .slick-arrow::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url(/images/slider-arrow.png);
        opacity: 1;
        top: 0;
        left: 0; }
  .template-homepage .template-homepage--loc .template-homepage--loc-link {
    text-align: center; }
    @media (min-width: 992px) {
      .template-homepage .template-homepage--loc .template-homepage--loc-link {
        text-align: left; } }

.template-homepage .template-homepage--presentation {
  margin: 60px 0 40px;
  position: relative;
  z-index: 1; }
  @media (min-width: 992px) {
    .template-homepage .template-homepage--presentation {
      margin: 150px 0 40px 0; } }
  @media (min-width: 1300px) {
    .template-homepage .template-homepage--presentation::before {
      content: "";
      position: absolute;
      font-size: 60rem;
      width: 1110px;
      height: 939px;
      background-image: url(/images/packimmo-presentation-bg.png);
      background-size: contain;
      background-position: center;
      top: -90px;
      left: -50px;
      z-index: 1; }
    .template-homepage .template-homepage--presentation::after {
      content: "";
      width: 984px;
      height: 823px;
      background: #00b0c8;
      background: -webkit-gradient(linear, left top, left bottom, from(#00b0c8), to(#00c8ac));
      background: -o-linear-gradient(top, #00b0c8 0, #00c8ac 100%);
      background: linear-gradient(180deg, #00b0c8 0, #00c8ac);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$primary",endColorstr="$secondary",GradientType=0);
      position: absolute;
      top: -70px;
      left: 70px;
      clip-path: url(#template-homepage--presentationMask);
      z-index: 0; } }
  @media (min-width: 992px) {
    .template-homepage .template-homepage--presentation.template-homepage--presentation-last-minutes {
      margin-top: 80px; } }
  .template-homepage .template-homepage--presentation.template-homepage--presentation-last-minutes .template-homepage--loc-list-item-infos-resumbloc > p {
    color: #ff6e79; }
  .template-homepage .template-homepage--presentation * {
    position: relative;
    z-index: 2; }
  .template-homepage .template-homepage--presentation h1 {
    font-size: 2.9rem;
    line-height: 34px;
    color: #162983;
    position: relative;
    margin: 0 0 40px 0;
    font-weight: 300;
    display: inline-block; }
    @media (min-width: 992px) {
      .template-homepage .template-homepage--presentation h1 {
        margin: 30px 0 0 60px; } }
    .template-homepage .template-homepage--presentation h1 p {
      position: relative; }
      .template-homepage .template-homepage--presentation h1 p strong {
        font-weight: 700; }
      .template-homepage .template-homepage--presentation h1 p::before {
        content: "\e916";
        font-family: icomoon;
        position: absolute;
        right: 0;
        transform: rotate(65deg);
        font-size: 1.5rem;
        font-weight: 800;
        color: #ff6e79; }
    .template-homepage .template-homepage--presentation h1::before {
      content: "\e91b";
      font-family: icomoon;
      position: absolute;
      top: -60px;
      left: 50px;
      font-size: 2rem;
      font-weight: 800;
      color: #f14a86;
      transform: rotate(75deg); }
    .template-homepage .template-homepage--presentation h1::after {
      content: "\e91e";
      font-family: icomoon;
      position: absolute;
      bottom: -30px;
      left: 20px;
      font-weight: 800;
      font-size: 2rem;
      color: #f14a86; }
  .template-homepage .template-homepage--presentation .template-homepage--presentation-text {
    display: flex;
    flex-direction: column; }
    .template-homepage .template-homepage--presentation .template-homepage--presentation-text p {
      color: #162983;
      margin: 40px 0 0 0; }
      @media (min-width: 992px) {
        .template-homepage .template-homepage--presentation .template-homepage--presentation-text p {
          margin: 0 0 0 150px; } }
    .template-homepage .template-homepage--presentation .template-homepage--presentation-text a {
      margin: 40px 0 30px auto; }
      @media (min-width: 992px) {
        .template-homepage .template-homepage--presentation .template-homepage--presentation-text a {
          margin: 40px 0 0 auto; } }
  @media (min-width: 1200px) {
    .template-homepage .template-homepage--presentation .template-homepage--presentation-imgOne {
      margin: -190px 0 0 0; }
      .template-homepage .template-homepage--presentation .template-homepage--presentation-imgOne img {
        clip-path: url(#template-homepage--presentation-imgOneMask);
        min-height: 480px;
        width: initial; } }
  @media (min-width: 1200px) {
    .template-homepage .template-homepage--presentation .template-homepage--presentation-imgTwo {
      transform: translateX(-70px); }
      .template-homepage .template-homepage--presentation .template-homepage--presentation-imgTwo img {
        clip-path: url(#template-homepage--presentation-imgTwoMask);
        min-height: 340px;
        width: initial; } }

.template-homepage .template-homepage--avis {
  background-color: #ff6e79;
  padding: 80px 0 50px 0; }
  @media (min-width: 1200px) {
    .template-homepage .template-homepage--avis {
      margin: -140px 0 0 0; } }
  .template-homepage .template-homepage--avis .template-homepage--avis-img {
    position: relative; }
    @media (min-width: 1200px) {
      .template-homepage .template-homepage--avis .template-homepage--avis-img .template-homepage--avis-img {
        position: absolute; }
        .template-homepage .template-homepage--avis .template-homepage--avis-img .template-homepage--avis-img.imgOne {
          top: -140px;
          width: 479px;
          height: 455px;
          right: 70px; }
          .template-homepage .template-homepage--avis .template-homepage--avis-img .template-homepage--avis-img.imgOne img {
            clip-path: url(#template-homepage--avis-imgOneMask);
            min-height: 455px; }
        .template-homepage .template-homepage--avis .template-homepage--avis-img .template-homepage--avis-img.imgTwo {
          bottom: 0;
          background-color: #fff;
          padding: 0;
          clip-path: url(#template-homepage--avis-imgTwoMask);
          width: 293px;
          height: 207px;
          display: flex;
          align-items: center;
          justify-content: center; } }
  .template-homepage .template-homepage--avis .template-homepage--avis-text {
    padding: 40px 15px 0 15px; }
    .template-homepage .template-homepage--avis .template-homepage--avis-text p {
      color: #fff; }
  .template-homepage .template-homepage--avis .template-homepage--avis-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between; }
    .template-homepage .template-homepage--avis .template-homepage--avis-title h2 {
      font-size: 2.9rem;
      line-height: 34px;
      color: #fff;
      position: relative;
      margin: 0 0 0 60px;
      font-weight: 300;
      display: inline-block; }
      .template-homepage .template-homepage--avis .template-homepage--avis-title h2 p {
        position: relative; }
        .template-homepage .template-homepage--avis .template-homepage--avis-title h2 p::before {
          content: "\e916";
          font-family: icomoon;
          position: absolute;
          right: 0;
          -webkit-transform: rotate(65deg);
          -ms-transform: rotate(65deg);
          transform: rotate(80deg);
          font-size: 1.5rem;
          font-weight: 800;
          color: #fff;
          top: 20px; }
          @media (min-width: 992px) {
            .template-homepage .template-homepage--avis .template-homepage--avis-title h2 p::before {
              right: -20px; } }
        .template-homepage .template-homepage--avis .template-homepage--avis-title h2 p strong {
          font-weight: 700; }
      .template-homepage .template-homepage--avis .template-homepage--avis-title h2::before {
        content: "\e91e";
        font-family: icomoon;
        position: absolute;
        top: -20px;
        right: 20px;
        font-weight: 800;
        font-size: 2rem;
        color: #fff; }
      .template-homepage .template-homepage--avis .template-homepage--avis-title h2::after {
        content: "\e91b";
        font-family: icomoon;
        position: absolute;
        bottom: 30px;
        left: -54px;
        font-size: 2rem;
        font-weight: 800;
        color: #fff;
        -webkit-transform: rotate(75deg);
        -ms-transform: rotate(75deg);
        transform: rotate(25deg); }
    .template-homepage .template-homepage--avis .template-homepage--avis-title a {
      position: relative;
      margin: 0 0 0 70px;
      z-index: 1; }

.template-homepage .template-homepage--actu {
  background-color: #ff8879;
  padding: 20px 0 10px 0; }
  .template-homepage .template-homepage--actu .template-homepage--actu-title h2 {
    font-size: 2.9rem;
    line-height: 34px;
    color: #fff;
    position: relative;
    margin: 0;
    font-weight: 300;
    display: inline-block; }
    .template-homepage .template-homepage--actu .template-homepage--actu-title h2 p {
      position: relative; }
      .template-homepage .template-homepage--actu .template-homepage--actu-title h2 p::before {
        content: "\e916";
        font-family: icomoon;
        position: absolute;
        right: 30px;
        -webkit-transform: rotate(65deg);
        -ms-transform: rotate(65deg);
        transform: rotate(80deg);
        font-size: 1.5rem;
        font-weight: 800;
        color: #fff;
        top: -10px; }
      .template-homepage .template-homepage--actu .template-homepage--actu-title h2 p strong {
        font-weight: 700; }
    .template-homepage .template-homepage--actu .template-homepage--actu-title h2::before {
      content: "\e91e";
      font-family: icomoon;
      position: absolute;
      top: -20px;
      left: -50px;
      font-weight: 800;
      font-size: 2rem;
      color: #fff; }
    .template-homepage .template-homepage--actu .template-homepage--actu-title h2::after {
      content: "\e91b";
      font-family: icomoon;
      position: absolute;
      bottom: 30px;
      right: -60px;
      font-size: 2rem;
      font-weight: 800;
      color: #fff;
      -webkit-transform: rotate(105deg);
      -ms-transform: rotate(105deg);
      transform: rotate(105deg); }
  .template-homepage .template-homepage--actu .template-homepage--actu-text p {
    color: #fff;
    margin: 0; }
  .template-homepage .template-homepage--actu .template-homepage--actu-link {
    position: relative;
    z-index: 1; }

.alert--form-search legend, .alert--form-infos legend {
  padding: 0 15px;
  text-transform: uppercase;
  margin: 30px 0 0 0 !important;
  display: block; }

/** RESPONSIVE **/
/** Personnalisation **/
/*	--------------------------------------------------
	--------------------------------------------------
		Gestion des fonts.
		Possibilité de créer une mixin par font

	--------------------------------------------------
	--------------------------------------------------*/
/** ICOMOON **/
@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon/icomoon.eot");
  src: url("fonts/icomoon/icomoon.eot") format("embedded-opentype"), url("fonts/icomoon/icomoon.ttf") format("truetype"), url("fonts/icomoon/icomoon.woff") format("woff"), url("fonts/icomoon/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"], .icomoon, .btn-prev:before, .btn-next:after, .btn-back:before, .overlibclose a:before, .slick-prev:before, .slick-next:before, .slick-dots li button:before, .sw-item--img:after, .sw-item--container:after {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-printer1:before {
  content: "\e922"; }

.icon-haricot3:before {
  content: "\e921"; }

.icon-haricot2:before {
  content: "\e920"; }

.icon-youtube1:before {
  content: "\e91f"; }

.icon-AVATAR_2:before {
  content: "\e915"; }

.icon-triangle:before {
  content: "\e91b"; }

.icon-rond:before {
  content: "\e91e"; }

.icon-area:before {
  content: "\e913"; }

.icon-AVATAR:before {
  content: "\e914"; }

.icon-croix:before {
  content: "\e916"; }

.icon-facebook1:before {
  content: "\e917";
  color: #3a559f; }

.icon-haricot:before {
  content: "\e918"; }

.icon-instagram1:before {
  content: "\e919"; }

.icon-map:before {
  content: "\e91a"; }

.icon-search1:before {
  content: "\e91c"; }

.icon-shopping-cart1:before {
  content: "\e91d"; }

.icon-bin:before {
  content: "\e912"; }

.icon-trashcan:before {
  content: "\e912"; }

.icon-remove:before {
  content: "\e912"; }

.icon-delete:before {
  content: "\e912"; }

.icon-recycle:before {
  content: "\e912"; }

.icon-dispose:before {
  content: "\e912"; }

.icon-folder-plus:before {
  content: "\e911"; }

.icon-directory:before {
  content: "\e911"; }

.icon-folder-add:before {
  content: "\e911"; }

.icon-cog:before {
  content: "\e90b"; }

.icon-gear:before {
  content: "\e90b"; }

.icon-settings:before {
  content: "\e90b"; }

.icon-generate:before {
  content: "\e90b"; }

.icon-make:before {
  content: "\e90b"; }

.icon-options:before {
  content: "\e90b"; }

.icon-switch:before {
  content: "\e90c"; }

.icon-power:before {
  content: "\e90c"; }

.icon-linkedin:before {
  content: "\e908"; }

.icon-instagram:before {
  content: "\e909"; }

.icon-share:before {
  content: "\e904"; }

.icon-arrow-right:before {
  content: "\e602"; }

.icon-arrow-left:before {
  content: "\e603"; }

.icon-user:before {
  content: "\e604"; }

.icon-printer:before {
  content: "\e605"; }

.icon-search:before {
  content: "\e90a"; }

.icon-remove-user:before {
  content: "\e90e"; }

.icon-download:before {
  content: "\e90f"; }

.icon-megaphone:before {
  content: "\e910"; }

.icon-chevron-small-left:before {
  content: "\e607"; }

.icon-chevron-small-right:before {
  content: "\e608"; }

.icon-chevron-thin-left:before {
  content: "\e609"; }

.icon-chevron-thin-right:before {
  content: "\e60a"; }

.icon-location-pin:before {
  content: "\e60b"; }

.icon-twitter:before {
  content: "\e60c"; }

.icon-locked:before {
  content: "\e90d"; }

.icon-secure:before {
  content: "\e90d"; }

.icon-calendar:before {
  content: "\e902"; }

.icon-pen:before {
  content: "\e903"; }

.icon-zephyr:before {
  content: "\e905"; }

.icon-close:before {
  content: "\e209"; }

.icon-done:before {
  content: "\e28a"; }

.icon-mail:before {
  content: "\e601"; }

.icon-youtube:before {
  content: "\e906"; }

.icon-tripadvisor:before {
  content: "\e900"; }

.icon-pinterest:before {
  content: "\e901"; }

.icon-phone:before {
  content: "\f095"; }

.icon-facebook:before {
  content: "\f09a"; }

.icon-chevron-double-small-right:before {
  content: "\e60d"; }

.icon-chevron-double-small-left:before {
  content: "\e60e"; }

.icon-chevron-double-thin-left:before {
  content: "\e60f"; }

.icon-chevron-double-thin-right:before {
  content: "\e610"; }

.icon-google:before {
  content: "\e600"; }

.icon-shopping-cart:before {
  content: "\e907"; }

/**
*
* Formulaires
*
**/
/*========================================
=            Form par default            =
========================================*/
/*----------  Header / Footer  ----------*/
.form--header, .form--footer {
  margin-top: 20px; }

/*----------  Checkbox  ----------*/
/*----------  Checkbox  ----------*/
/*----------  Checkbox & Radio other  ----------*/
.checkbox--other label, .radio--other label {
  width: auto; }

.checkbox--other input[type="checkbox"], .radio--other input[type="checkbox"] {
  margin-top: 8px; }

.checkbox--other-input, .radio--other-input {
  width: auto;
  display: inline;
  margin-left: 1rem; }

/*----------  Radios visual  ----------*/
/*=====  End of Form par default  ======*/
/**
*
* Composants spécifique au CMS e-majine
*
**/
/* -----------------
Champ ajout d'image
------------------- */
#html5Behaviorspicture_input {
  margin-left: 200px; }

.imagePreview {
  overflow: hidden;
  width: 200px;
  height: 200px; }

.imagePreview img {
  max-width: none; }

.imagePreviewCropLayer {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  margin: -200px 0 0 -300px;
  width: 600px;
  height: 410px;
  padding: 10px;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.7); }

.imagePreviewCropLayer > span {
  float: right;
  color: #31a5da;
  padding: 5px 10px;
  margin: 10px 0 0 10px;
  font-size: 12px;
  border: 1px solid #31a5da;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  cursor: pointer; }

.imagePreviewCropLayer > span:last-child {
  border: 1px solid #fff; }

.imagePreviewCropLayer > span:hover {
  color: #fff;
  background: #31a5da;
  border: 1px solid #31a5da; }

.imagePreviewCrop {
  height: 350px;
  background: #eee;
  border: 1px solid #ddd;
  overflow-y: auto; }

.datasFile {
  float: left;
  margin-top: 10px;
  font: bold 12px/32px Arial, Verdana, sans-serif; }

.datasFile > span {
  margin-right: 20px; }

.datasFile strong {
  font-weight: normal; }

#fd_noir {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  width: 100%;
  height: 100%;
  background: url(/images/bg_mask.png); }

.arrowBlack {
  position: absolute;
  left: 5px;
  bottom: -5px;
  display: block;
  width: 10px !important;
  height: 5px !important;
  padding: 0;
  margin: 0;
  background: url(/common_images/community/bg_arrow.png); }

.arrowBlack.reverse, .arrowContent.reverse .arrowBlack {
  left: auto;
  right: 5px; }

.arrowContent {
  position: absolute;
  top: -25px;
  left: 0;
  z-index: 200;
  display: none;
  padding: 0 5px;
  font-size: 11px;
  line-height: 20px;
  color: #fff;
  background: #000;
  white-space: nowrap; }

/*===================================
=        Calendrier e-majine        =
===================================*/
#overDiv.calendar {
  margin-left: -153px;
  margin-top: 6px; }

#overlibcontent {
  background: #fff;
  padding: 15px;
  font-size: 16px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.24); }
  #overlibcontent a {
    text-decoration: none; }
  #overlibcontent .nav:before, #overlibcontent .nav:after {
    display: none; }
  #overlibcontent caption {
    padding-top: 0;
    font-weight: 500;
    color: #212529;
    text-align: center; }

.overlibcaption {
  display: none; }

.overlibclose {
  position: absolute;
  top: 0px;
  right: 0px; }
  .overlibclose a {
    text-decoration: none;
    width: 30px;
    float: right;
    height: 30px;
    border-radius: 50%;
    display: block;
    overflow: hidden;
    color: #00b0c8; }
    .overlibclose a:before {
      content: "\e209";
      padding: 6px 0;
      text-align: center;
      display: block;
      font-size: 20px; }
    .overlibclose a:hover, .overlibclose a:focus {
      background: #fff;
      color: #212529; }

#overlibfooter, .overlibcaption {
  display: none; }

.calendar caption {
  caption-side: top; }

.calendar .nav {
  display: table-row; }

.calendar .nav th a {
  border: none; }
  .calendar .nav th a:hover, .calendar .nav th a:focus {
    border: none;
    background: transparent;
    color: #00b0c8; }

.calendar table {
  border-collapse: separate;
  border-spacing: 1px;
  text-align: center;
  table-layout: fixed; }

.calendar th {
  font-weight: normal;
  padding: 2px; }

.calendar th, .calendar td {
  text-align: center;
  font-size: 15px;
  min-width: 32px; }
  .calendar th a, .calendar td a {
    display: block;
    border: 1px solid #c2c2c2;
    padding: 2px; }
    .calendar th a:hover, .calendar th a:focus, .calendar td a:hover, .calendar td a:focus {
      background: #00b0c8;
      color: #fff;
      border-color: #00b0c8; }

.calendar .no_day {
  border: none; }

.calendar .past_day {
  background: #dfdfdf; }
  .calendar .past_day a {
    color: #6c757d; }
    .calendar .past_day a:hover, .calendar .past_day a:focus {
      background: #00b0c8;
      color: #fff;
      border-color: #00b0c8; }

/*=========================================
=            Article en bloc            =
=========================================*/
.bloc-simple, .bloc-double {
  margin-top: 0;
  margin-bottom: 0; }

.medias, .map {
  margin-bottom: 1rem; }

.bloc-double + .bloc-double ul, .intext_left + div ul {
  list-style-position: inside; }

.intext_left, .intext_right {
  float: none; }
  .intext_left .bloc--img, .intext_right .bloc--img {
    text-align: center;
    margin-bottom: 1rem; }

.above_left, .above_right, .above_center {
  margin-bottom: 1rem; }

.below_left, .below_right, .below_center {
  margin-bottom: 1rem; }

.above_left, .below_left {
  text-align: left; }

.above_right, .below_right {
  text-align: right; }

.above_center, .below_center {
  text-align: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.6rem;
  margin-left: -0.6rem; }
  .above_center .bloc--img, .below_center .bloc--img {
    max-width: 100%;
    margin-bottom: 1rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    padding-left: 0.6rem;
    padding-right: 0.6rem; }

@media (min-width: 768px) {
  .intext_left {
    float: left;
    margin-right: 1rem; }
    .intext_left .bloc--img {
      text-align: left; }
  .intext_right {
    float: right;
    margin-left: 1rem; }
    .intext_right .bloc--img {
      text-align: right; } }

/*=====  End of Article en bloc  ======*/
/*==================================
=            Re-captcha            =
==================================*/
#recaptcha_image, .recaptchatable .recaptcha_image_cell center img, .recaptchatable .recaptcha_image_cell center, .recaptchatable .recaptcha_image_cell, .recaptchatable #recaptcha_response_field {
  height: auto !important;
  width: 100% !important; }

#captcha .recaptchatable {
  border: none !important; }

.recaptcha {
  max-width: 400px; }

#recaptcha_response_field {
  border: 1px solid #cccccc !important;
  padding: 2px 5px !important;
  color: #000000 !important; }

#recaptcha_table td + td + td {
  padding: 0 !important; }

.recaptcha a img {
  max-width: none; }

#recaptcha_logo, #recaptcha_privacy {
  display: none; }

/*=====  End of Re-captcha  ======*/
/*=======================================
=            Alerte e-majine            =
=======================================*/
/* etoile pour champs obligatoire */
.form .invalid-feedback {
  display: block; }

/* Message "Champ obligatoire" */
/* Etat invalide des champs */
/* Reste à tester */
/* validation de l'inscription au compte : Votre compte est maintenant actif. Un message comprenant votre login et votre mot de passe a été envoyé à l'adresse ...*/
/*=====  End of Alerte e-majine  ======*/
/*=============================================
=            check password           =
=============================================*/
.strength_password, .confirmpasswd {
  padding-left: 10px; }

/*=====  End of check password  ======*/
/* slider */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }

.slick-prev:before, .slick-next:before {
  font-size: 20px;
  line-height: 1;
  color: black;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  .slick-prev[dir="rtl"] {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: ""; }
    .slick-prev:before[dir="rtl"] {
      content: ""; }

.slick-next {
  right: -25px; }
  .slick-next[dir="rtl"] {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: ""; }
    .slick-next:before[dir="rtl"] {
      content: ""; }

/* Dots */
.slick-dotted .slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Social wall */
.box-sw--filters {
  margin-bottom: 30px;
  margin-top: 20px;
  text-align: center; }

.box-sw--filters-item {
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 5px; }
  .box-sw--filters-item:hover:after, .box-sw--filters-item:focus:after {
    transform: translate(-50%, -50%) scale(1.1);
    transition: all .3s ease; }
  .box-sw--filters-item:after {
    content: '';
    width: 100%;
    height: 100%;
    background: #dee2e6;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s ease;
    z-index: -1;
    border-radius: 50%; }

.box-sw--ico {
  color: #212529;
  font-size: 20px; }

.socal-wall-active:after {
  width: 60px;
  height: 60px;
  border-radius: 30px; }

.socal-wall-active:after {
  color: #00b0c8; }

.sw-item {
  margin-bottom: 30px; }

.sw-item--link {
  background-color: #fff;
  display: inline-block;
  color: #212529;
  width: 100%;
  overflow: hidden; }
  .sw-item--link:hover, .sw-item--link:focus {
    color: #212529;
    text-decoration: none; }
    .sw-item--link:hover .sw-item--img:before, .sw-item--link:focus .sw-item--img:before {
      opacity: 1; }
    .sw-item--link:hover .sw-item--img:after, .sw-item--link:focus .sw-item--img:after {
      opacity: 1; }
    .sw-item--link:hover .sw-item--img img, .sw-item--link:focus .sw-item--img img {
      transform: scale(1.05); }

.sw-item--img {
  position: relative;
  text-align: center;
  overflow: hidden; }
  .sw-item--img:before {
    content: '';
    background-color: #fff;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .4s ease;
    z-index: 2; }
  .sw-item--img:after {
    content: '\e209';
    color: #00b0c8;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: all .4s ease;
    z-index: 3; }
  .sw-item--img img {
    width: 100%;
    max-width: 100%;
    transition: all .4s ease; }

.sw-item--container {
  position: relative;
  z-index: 1;
  padding-top: 30px; }

.sw-item--text {
  word-wrap: break-word;
  position: relative;
  z-index: 2; }

.sw-item--container:before {
  content: '';
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #ced4da;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%); }

.sw-item--container:after {
  content: '';
  color: #212529;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 25px;
  top: -12px; }

.sw-item_fb .sw-item--container:after {
  content: '\f09a'; }

.sw-item_youtube .sw-item--container:after {
  content: '\e906'; }

.sw-item_insta .sw-item--container:after {
  content: '\e909'; }

.sw-item_pinterest .sw-item--container:after {
  content: '\e901';
  font-size: 23px;
  top: -12px; }

.sw-item--date {
  color: #00b0c8;
  margin-top: 10px;
  display: inline-block; }

.box-sw--list {
  display: block; }

.sw-item {
  float: left;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .sw-item {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 768px) {
    .sw-item {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  @media (min-width: 992px) {
    .sw-item {
      flex: 0 0 25%;
      max-width: 25%; } }

@media (min-width: 576px) {
  .box-social-wall--filters {
    margin-top: -60px;
    text-align: right; } }

/*fancybox 3*/
