/**
*
* Navigation
* Menu, breadcrumb, pagination, pager, ...
*
**/

/* gestion du tactile quand le menu repasse en desktop */
@include media-breakpoint-up(md) {
  .navbar-nav .nav-item:hover > .dropdown-menu, .navbar-nav .nav-item:focus > .dropdown-menu{ display: block;  }
  //à tester pour ios (bootstrap gère ca?)
  .no-touch .navbar-nav li:hover > ul.dropdown-menu{ display: none; }
  .no-touch .navbar-nav li.open > ul.dropdown-menu { display: block;}

//dropdown
.navbar-nav .dropdown-menu{margin-top:0;}
//niveau 3 positionné à droite
  .dropdown-menu .dropdown-menu{
     top: 0;
     left: 100%;
  }
}


/*=======================================
=            Animation Menu             =
=======================================*/

// .dropdown{
//     &.open, &:hover, &:focus{
//         & > .dropdown-menu {
//           -webkit-transform: scale(1, 1);
//           transform: scale(1, 1);
//         }
//     }
// }
//
// .dropdown-menu {
//   transform-origin: top;
//   animation-fill-mode: forwards;
//   transform: scale(1, 0);
//   display: block;
//   transition: all 0.2s ease-out;
// }


/*=====  End of Animation Menu ======*/

ul#menu-main {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    height: 100%;
    width: 100%;
    background-color: #efefef;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 70px 30px 30px 10px;
    @include media-breakpoint-up(lg) {
        padding: 0;
        height: initial;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        top: initial;
        left: initial;
        background-color: transparent;
    }
    > a {
        order: 3;
        margin: 0;
        padding: 0;
    }
    > li {
        order: 4;
        &:nth-child(2) {
            order: 1;
        }
        &:nth-child(3) {
            order: 2;
        }
        > a {
            text-transform: uppercase;
            font-weight: 700;
            color: $primary;
            font-size: 1.2rem;
            transition: color .3s;
            @include media-breakpoint-up(lg) {
                font-size: 1rem;
            }
        }
        .dropdown-menu {
            width: 100%;
            border: transparent;
            background-color: #efefef;
            a {
                text-transform: uppercase;
                font-size: 0.9rem;
                font-weight: 700;
                color: $third !important;
            }
        }
        &:hover {
            a {
                color: $third;
            }
        }
    }
}

// FIL D'ARIANE
ol.breadcrumb {
    border-radius: 0;
    background-color: transparent;
    .breadcrumb-item{
        &+.breadcrumb-item{
            &:before {
                content: "-";
                padding: 0 0 0 5px;
                color: $primary;
            }
        }
    }
}

// pagination
ul.pagination {
    margin: 0;
    background-color: $primary;
    padding: 40px;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 0;
    &::before, &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 100%;
        height: 100%;
        background-color: $primary;
        width: 1000px;
    }
    &::after {
        right: initial;
        left: 100%;
    }
    li {
        display: flex;
        align-items: center;
        margin: 0 3px;
        a {
            padding: 0;
            background-color: transparent;
            color: #fff;
            border: transparent;
            border-radius: 0;
            line-height: 1;
            &:hover, &:active, &:focus {
                background-color: transparent;
                color: #fff;
            }
        }
        &::after {
            content: ".";
            color: #fff;
            margin: 0 5px 0 12px;
        }
        &:last-child, &.pagerNext, &.pagerLast {
            &::after {
                display: none;
            }
        }
    }
}
