/**
*
* Eléments réutilisables :
* boutons, icônes, popin (lightbox, notification…), slider (styles communs)…
*
**/
body{
	min-height:100vh; /* 1 */
	display:flex; /* 2 */
	flex-direction:column; /* 3 */
	font-family: 'Sora', sans-serif;
}
.main{
	flex-grow:1; /* 4 */
}

/* on évite les débordements */
img{max-width: 100%;}
.map{
    max-width: 100%;
     overflow: hidden;
    > div {
        max-width: 100%;
        overflow: hidden;
    }
}

/* title */

h1 {
	font-size: 3.1rem;
	color: $color04;
	font-weight: bold;
	margin-bottom: 30px;
}

h2, .h2 {
	color: $color03;
}

h3, .h3 {
	color: $color05;
}

/* breadcrumb */

.breadcrumb {
	background-color: transparent;
}

.breadcrumb-item+.breadcrumb-item:before {
	content: none;
}

.breadcrumb-item {

	&:before {
		content: '-'!important;
		padding: 0 0px 0 5px!important;
	}

	&:first-child, &:last-child {

		&:before {
			content: none!important;
		}
	}

	a {
		color: $body-color;

		&:hover, &:focus {
			color: $primary;
			text-decoration: none;
		}
	}
}

/* formulaire */

.form_contact {

	.form-control {
		border-radius: 20px!important;
		border: 2px solid $color03!important;
	}

	.form-check-label {
		margin-left: 20px;
	}
}

/* pagination */

.pagination {
	justify-content: center;
    margin-top: 20px;
}

/* boutons */
.btn.btn-primary {
    background-color: transparent;
    border: transparent;
    border-radius: 0;
    color: $primary;
    font-weight: 700;
	display: inline-block;
    padding: 10px 40px 10px 20px;
    position: relative;
	&:not(.gradient) {
		&::before {
		    content: "";
		    width: 0;
		    height: 100%;
		    position: absolute;
		    top: 0;
		    left: 0;
		    background-color: $primary;
		    transition: width .3s;
		    z-index: -1;
		}
	}
	&::after {
	    content: "\e91b";
	    font-family: icomoon;
	    position: absolute;
	    top: 50%;
	    right: 16px;
	    transform: translateY(-50%) rotate(-150deg);
	}
	&:hover, &:active, &:focus {
		background-color: transparent !important;
		outline: none;
		color: #fff;
		box-shadow: none !important;
		text-decoration: none;
		&::before {
			width: 100%;
		}
	}
}
.btn.btn-secondary {
    background-color: transparent;
    border: transparent;
    border-radius: 0;
    position: relative;
    color: #fff;
    text-align: left;
    font-weight: 600;
    line-height: 1;
    padding: 49px 43px 45px 37px;
	&:not(.gradient) {
		&::before {
		    content: "\e918";
		    font-family: icomoon;
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    font-size: 11rem;
		    color: $primary;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    z-index: -1;
			transition: color .3s, transform .3s;
		}
	}
	&::after {
	    content: "\e91b";
	    font-family: icomoon;
	    position: absolute;
	    top: 50%;
	    right: 16px;
	    transform: translateY(-50%) rotate(88deg);
	    font-weight: 800;
	    font-size: 1.1rem;
	}
	&:hover, &:active, &:focus {
		background-color: transparent !important;
		outline: none;
	    color: #fff;
		box-shadow: none !important;
		text-decoration: none;
		&::before {
			transform: scale(1.1);
	    	color: $secondary;
		}
	}
	&.gradient {
		background-color: $fifth;
	    background-image: -webkit-gradient(linear,left top,right top,color-stop(1%,$fifth),to($fourth));
	    background-image: -o-linear-gradient(left,$fifth 1%,$fourth 100%);
	    background-image: linear-gradient(90deg,$fifth 1%,$fourth);
	    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$fifth",endColorstr="$fourth",GradientType=1);
	    clip-path: url(#svgPath-link);
		padding: 49px 51px 58px 37px;
		transition: background-color .3s, clip-path .3s;
		&:hover, &:active, &:focus {
			background: $fourth !important;
		}
	}
	&.white {
    	color: $fifth;
		&::before {
			color: #fff;
		}
		&::after {
	    	color: $fifth;
		}
	}
	&.sixth {
    	color: #fff;
		&::before {
			color: $sixth;
		}
		&::after {
	    	color: #fff;
		}
		&:hover {
	    	color: $sixth;
			&::before {
				color: #fff;
			}
			&::after {
		    	color: $sixth;
			}
		}
	}
}
.btn-prev{
    &:before{
        content: '\e603';
        @extend .icomoon;
        padding-right: 0.5rem;
        vertical-align: -2px;
    }
}
.btn-next{
    &:after{
        content: '\e602';
        @extend .icomoon;
        padding-left: 0.5rem;
        vertical-align: -2px;
    }
}
.btn-back{
    &:before{
        content: '\e609';
        @extend .icomoon;
        padding-right: 0.5rem;
        vertical-align: -2px;
    }
}
.btn-back {
    background-color: $third;
    color: #fff;
    border-radius: 30px;
	&:hover, &:active, &:focus {
		color: #fff;
		box-shadow: none;
	}
}
/* galerie d'images*/
.cover {
	overflow: hidden;
	img {
	    object-fit: cover;
	    font-family: "object-fit: cover;";
	    height: 100%;
	    width: 100%;
	}
}
.article-gallery{
    .lightbox{
        overflow: hidden;
        max-height: 12.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.5rem;
        //création d'un colonnage pour les éléments de galerie
        @include make-col-ready();
        @include media-breakpoint-up(sm) {
           @include make-col(6);
        }
        @include media-breakpoint-up(md) {
           @include make-col(4);
        }
        @include media-breakpoint-up(lg) {
           @include make-col(3);
        }
    }
}

/* pagination*/
.pager_current_page{
    @extend .active;
}


/*===================================
=            Alert Oldie            =
===================================*/

.oldie {
	.alert-ie {
		border-top:10px solid #e44204;
		& > div {
			padding: 20px 70px;
			background: url(/images/alert-ie.png) left center no-repeat;
		}
		strong { color:#e44204; }
	}
}

/*=====  End of Alert Oldie  ======*/


/*==================================
=            Skip links            =
==================================*/

.skip-nav {
	position: absolute;
	padding: 0;
	list-style: none;
	z-index:9999;
	a {
		position: absolute;
		left: -7000px;
		padding: 0.5em;
		background: $gray-800;
		color: white;
		text-decoration: none;
		&:focus{
			position: static;
		}
	}
}

/*=====  End of Skip links  ======*/


/*==========================================
=            Cookies validation            =
==========================================*/

.alert-cookie {
	transition:none;
	overflow: hidden;
	text-align: center;
	background-color: $body-color;
	background-color: rgba(0,0,0,0.85);
	color:#fff;
	animation: cookie-anim 1s ease-in;
	padding: 10px;
	margin: 0;

	&.is-hidden{
		animation: none;
		max-height: 0;
		padding:0;
		margin: 0;
		transition: all 0.5s ease-out;
	}

	&.fixed-bottom{
		position: fixed;
		width: 100%;
		left: 0;
		bottom: 0;
	}
}
@keyframes cookie-anim {
	0% { max-height: 0; padding:0; margin:0;}
	100% { max-height: 70px; padding: 10px; }
}
.alert-cookie--link, .alert-cookie--link:visited {
	display: inline-block;
	color: #fff;
	&:hover, &:focus{
		color: #fff;
	}
}
.alert-cookie--btn {
	/* Retrouver les class .btn et .btn-primary directement dans le fichier /zephyr/cookie.js */
}

/*=====  End of Cookies validation  ======*/
